<template>
  <div class="overflow-scroll">
    <a-card
      :bordered="false"
      :tabList="tabListNoTitle"
      :activeTabKey="noTitleKey"
      @tabChange="key => onTabChange(key, 'noTitleKey')"
    >
      <a-card v-if="noTitleKey === 'baseDetail'" :bordered="false">
        <BaseDetailEdit
          :isREP="isREP"
          :initPage="initPage"
          :baseInfo="this.baseInfo"
          :buildingInfo="this.buildingInfo"
          :facilityMedias="this.mediaUrl.facilityMedias || []"
          :floorPlanMedias="this.mediaUrl.floorPlanMedias || []"
          :designRenderMedias="this.mediaUrl.designRenderMedias || []"
        ></BaseDetailEdit>
      </a-card>
      <a-card v-if="noTitleKey === 'rentDetail'" :bordered="false">
        <RentDetailEdit
          :isREP="isREP"
          :initPage="initPage"
          :baseInfo="this.baseInfo"
        ></RentDetailEdit>
      </a-card>
      <a-card v-if="noTitleKey === 'licenceDetail'" :bordered="false">
        <LicenceDetailEdit
          :initPage="initPage"
          :baseInfo="this.baseInfo"
          :buildingInfo="this.buildingInfo"
          :foodCertificateMedias="this.mediaUrl.foodCertificateMedias || []"
          :bizLicenseMedias="this.mediaUrl.bizLicenseMedias || []"
          :fsbMedias="this.mediaUrl.fsbMedias || []"
          :fdaFloorPlanMedias="this.mediaUrl.fdaFloorPlanMedias || []"
          :completionMediaUrls="this.mediaUrl.completionMediaUrls || []"
          :fsbSecurityCheckPhoto="this.mediaUrl.fsbSecurityCheckPhoto || []"
          :otherMediaUrls="this.mediaUrl.otherMediaUrls || []"
        ></LicenceDetailEdit>
      </a-card>
      <a-card v-if="noTitleKey === 'costDetail'" :bordered="false">
        <CostDetailEdit
          :initPage="initPage"
          :baseInfo="this.baseInfo"
          :dataQueryCost="this.dataQueryCost"
          :buildingInfo="this.buildingInfo"
        ></CostDetailEdit>
      </a-card>
    </a-card>
  </div>
</template>

<script>
  
import moment from "moment";
import Bus from "@/bus";

import BaseDetailEdit from "./components/BaseDetailEdit";
import RentDetailEdit from "./components/RentDetailEdit";
import LicenceDetailEdit from "./components/LicenceDetailEdit";
import CostDetailEdit from "./components/CostDetailEdit";

export default {
  name: "storeDetaiEditNew",
  components: {
    BaseDetailEdit,
    RentDetailEdit,
    LicenceDetailEdit,
    CostDetailEdit
  },
  data() {
    return {
      // 路由ID
      id: this.$route.query.editDetailId,
      // 数据
      baseInfo: {},
      // 建筑信息
      buildingInfo: {},
      // 费用信息
      dataQueryCost: {},
      //  现在的事件
      noTitleKey: "baseDetail",
      // 固定tab头部
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "基本信息"
        },
        {
          key: "rentDetail",
          tab: "承租信息"
        },
        {
          key: "licenceDetail",
          tab: "证照信息"
        },
        {
          key: "costDetail",
          tab: "费用信息"
        }
      ],
      //门店状态
      storeStatus: [
        {
          id: 1,
          name: "已签约"
        },
        {
          id: 2,
          name: "施工中"
        },
        {
          id: 3,
          name: "已开业"
        },
        {
          id: 4,
          name: "停业中"
        },
        {
          id: 5,
          name: "已关店"
        }
      ],
      // 照片对象
      // facilityMedias 头部卡片资料 -- 未开展
      // floorPlanMedias 工程平面图
      // designRenderMedias 设计渲染图
      // foodCertificateMedias 食品经营许可证图片 -- 未开展
      // bizLicenseMedias 营业执照图片 -- 未开展
      // fsbMedias 消防许可证图片 -- 未开展
      // fdaFloorPlanMedias 食药监平面图图片 -- 未开展
      mediaUrl: {
        facilityMedias: [],
        floorPlanMedias: [],
        designRenderMedias: [],
        foodCertificateMedias: [],
        bizLicenseMedias: [],
        fsbMedias: [],
        fdaFloorPlanMedias: [],
        completionMediaUrls: [],
        mediaUrl: [],
        otherMediaUrls: []
      }
    };
  },
  created() {
    this.init();
  },
  computed: {
    isREP() {
      return !!this.buildingInfo.propertyCode;
    }
  },
  methods: {
    // 工具
    // pushType
    imgPushType(arr) {
      if (!arr) {
        return [];
      }
      let that = this;
      let aa = [];
      arr.forEach(item => {
        let type = that.checkURLType(item);
        aa.push({
          url: item,
          fType: type
        });
      });
      return aa;
    },
    // 判断图片
    checkURLType(item) {
      if (/\.(jpg|jpeg|png|gif|GIF|JPG|PNG)$/.test(item)) {
        return "img";
      } else if (/\.pdf$/.test(item)) {
        return "pdf";
      } else {
        return "other";
      }
    },
    moment,
    // 切换tab
    onTabChange(key, type) {
      this[type] = key;
    },
    // 初始化
    init() {
      this.getBaseInfo();
      this.getBuildingInfo();
      this.queryCost();
    },
    // 头部信息查询
    getBaseInfo() {
      axios({
        url: "/facility/query",
        method: "GET",
        withCredentials: true,
        params: {
          facilityId: this.id
        }
      }).then(res => {
        if (res.data.obj) {
          this.baseInfo = res.data.obj;
          if (this.baseInfo.status) {
            this.storeStatus.forEach(item => {
              if (item.id == this.baseInfo.status) {
                this.changeStatue = item.name;
              }
            });
          }
          // 门店媒体信息"
          this.mediaUrl.facilityMedias =
            this.imgPushType(this.baseInfo.facilityMediaUrls) || [];
          // "营业执照图片"
          this.mediaUrl.bizLicenseMedias =
            this.imgPushType(this.baseInfo.bizLicensePhoto) || [];
          // "食品经营许可证照片"
          this.mediaUrl.foodCertificateMedias =
            this.imgPushType(this.baseInfo.foodCertificatePhoto) || [];
          // "消防验收意见书"
          this.mediaUrl.fsbMedias =
            this.imgPushType(this.baseInfo.fsbPhoto) || [];
          // "消防安全检查合格证"
          this.mediaUrl.fsbSecurityCheckPhoto =
            this.imgPushType(this.baseInfo.fsbSecurityCheckPhoto) || [];
        }
      });
    },
    // 头部建筑信息查询
    getBuildingInfo() {
      axios({
        url: "facility/query/building",
        method: "GET",
        withCredentials: true,
        params: {
          facilityId: this.id
        }
      }).then(res => {
        if (res.data.obj) {
          this.buildingInfo = res.data.obj;
          // 食药监平面图图片"
          this.mediaUrl.fdaFloorPlanMedias =
            this.imgPushType(this.buildingInfo.fdaFloorPlanMediaUrls) || [];
          // "工程平面图"
          this.mediaUrl.floorPlanMedias =
            this.imgPushType(this.buildingInfo.facilityFloorPlanMediaUrls) ||
            [];
          // "门店3D渲染图"
          this.mediaUrl.designRenderMedias =
            this.imgPushType(this.buildingInfo.facilityDesignRenderMediaUrls) ||
            [];
          // "竣工验收备案"
          this.mediaUrl.completionMediaUrls =
            this.imgPushType(this.buildingInfo.completionMediaUrls) || [];
          // "其他"
          this.mediaUrl.otherMediaUrls =
            this.imgPushType(this.buildingInfo.otherMediaUrls) || [];
        }
      });
    },
    //费用信息
    queryCost() {
      axios({
        url:   this.$baseUrl + "/facility/query/cost",
        method: "GET",
        withCredentials: true,
        params: {
          facilityId: this.id
        }
      }).then(res => {
        if (res.data.returnCode == 200) {
          this.dataQueryCost = res.data.obj || {};
        }
      });
    },
    // 刷新页面
    initPage() {
      this.baseInfo = [];
      this.buildingInfo = [];
      this.dataQueryCost = [];
      this.mediaUrl = {
        facilityMedias: [],
        floorPlanMedias: [],
        designRenderMedias: [],
        foodCertificateMedias: [],
        bizLicenseMedias: [],
        fsbMedias: [],
        fdaFloorPlanMedias: [],
        completionMediaUrls: [],
        fsbSecurityCheckPhoto: [],
        otherMediaUrls: []
      };
      let key = this.noTitleKey;
      this.noTitleKey = "";
      this.noTitleKey = key;
      this.init();
    }
  },
};
</script>
<style lang="less">
.fileBox .ant-form-item-children {
  display: flex;
}
.fileBox .uploadBox {
  margin: 1em;
}
.otherBox .imgItem a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
}
.imgBox,
.otherBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  .imgItem {
    position: relative;
    height: 6em;
    width: 6em;
    margin: 1em;
    .remove {
      position: absolute;
      display: inline-block;
      right: -5px;
      top: -5px;
      width: 15px;
      height: 15px;
      line-height: 15px;
      font-size: 8px;
      text-align: center;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.65);
      color: #fff;
      box-sizing: border-box;
      cursor: pointer;
      z-index: 998;
    }
  }
  img {
    border: 1px solid #f0f2f5;
    border-radius: 4px;
        height: 6em;
    width: 6em;
  }
  .imgBox .ant-upload-picture-card-wrapper {
    height: 100%;
  }
}
.otherBox .imgItem .remove {
  right: -8px;
  top: -5px;
}
</style>
<style lang="less" scoped></style>
