<template>
  <div style="padding:0;">
    <!-- 基本信息 -->
    <a-spin :spinning="basicLoading">
      <DescriptionList
        title="门店信息"
        size="large"
        style="margin-bottom: 15px; "
      >
        <a-card :bordered="false" style="margin-left: -16px">
          <div class="table-operator">
            <a-form layout="inline" :form="baseDetailForm">
              <a-row :gutter="5" style="line-height: 4">
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店编号" label-width="50px">
                    <a-input
                      style="width: 240px"
                      :disabled="isREP"
                      v-decorator="[
                        'code',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入'
                            }
                          ],
                          initialValue: baseInfo.code
                        }
                      ]"
                      placeholder="无"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="16" :sm="24">
                  <a-form-item label="门店名称">
                    <a-input
                      :disabled="isREP"
                      style="width: 500px"
                      v-decorator="[
                        'name',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入'
                            }
                          ],
                          initialValue: baseInfo.name
                        }
                      ]"
                      placeholder="无"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店类型">
                    <a-select
                      style="width: 240px"
                      :disabled="isREP"
                      v-decorator="[
                        'type',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择'
                            }
                          ],
                          initialValue: baseInfo.type
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option v-for="item in storeType" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店性质">
                    <a-select
                      style="width: 240px"
                      v-decorator="[
                        'attribute',
                        {
                          rules: [
                            {
                              required: false,
                              message: '请选择'
                            }
                          ],
                          initialValue: this.changeSelect(baseInfo.attribute)
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in storeNature"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="城市">
                    <a-select
                      @change="selectCity"
                      style="width: 240px"
                      v-decorator="[
                        'city',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择'
                            }
                          ],
                          initialValue: baseInfo.cityId
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option v-for="item in cityList" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="所属厨房">
                    <a-select
                      style="width: 240px"
                      v-decorator="[
                        'facilityBrand',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择'
                            }
                          ],
                          initialValue: baseInfo.facilityBrand
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in brandNameList"
                        :key="item.code"
                      >
                        {{ item.optionText }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="门店地址">
                    <a-input
                      style="width: 607px"
                      :disabled="isREP"
                      v-decorator="[
                        'address',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入'
                            }
                          ],
                          initialValue: baseInfo.address
                        }
                      ]"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店楼层">
                    <a-input
                      style="width: 240px"
                      disabled
                      v-decorator="[
                        'floor',
                        {
                          rules: [
                            {
                              message: '请输入'
                            }
                          ],
                          initialValue: baseInfo.floor
                        }
                      ]"
                      placeholder="无"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="16" :sm="24">
                  <a-form-item label="取餐往返时间(秒)">
                    <a-input
                      style="width: 240px"
                      :disabled="isREP"
                      v-decorator="[
                        'roundtripTime',
                        {
                          initialValue: baseInfo.roundtripTime
                        }
                      ]"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="项目介绍">
                    <a-input
                      style="width: 974px"
                      v-decorator="[
                        'description',
                        {
                          rules: [
                            {
                              message: '请输入'
                            }
                          ],
                          initialValue: baseInfo.description
                        }
                      ]"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <!-- 上传不多于5张 -->
              <a-row :gutter="5" style="line-height: 8;margin-top:18px;">
                <a-col :md="12" :sm="24">
                  <a-form-item
                    label="门店照片"
                    class="fileBox"
                    :labelCol="{ span: 24 }"
                  >
                    <div class="imgBox">
                      <div
                        v-for="(item, index) in facilityMedias.filter(
                          item => item.fType == 'img'
                        )"
                        :key="index"
                        class="imgItem"
                      >
                        <img
                          slot="cover"
                          alt="example"
                          @click="viewImg(item.url)"
                          :src="item.url"
                          class="image-edit"
                        />
                        <span
                          class="remove"
                          @click="removeFile(item, 'facilityMedias')"
                          >X</span
                        >
                      </div>
                      <div
                        v-for="(item, index) in facilityMedias.filter(
                          item => item.fType == 'pdf'
                        )"
                        :key="`pdf${index}`"
                        class="imgItem"
                      >
                        <div @click="viewImg(item.url)" class="embed-cover"></div>
                        <embed :src="`${item.url}#toolbar=0&navpanes=0&scrollbar=0&view=Fit`" class="embed-item" />
                        <span
                          class="remove"
                          @click="removeFile(item, 'facilityMedias')"
                          >X</span
                        >
                      </div>
                    </div>
                    <div class="otherBox">
                      <div
                        v-for="(item, index) in facilityMedias.filter(
                          item => item.fType == 'other'
                        )"
                        :key="index"
                        class="imgItem"
                      >
                        <a target="_blank" :href="item.url" :download="item.url"
                          >[附件{{ index + 1 }}]</a
                        >
                        <span
                          class="remove"
                          @click="removeFile(item, 'facilityMedias')"
                          >X</span
                        >
                      </div>
                    </div>
                    <CompressUpload :onFinish="onFacilityUploadFinish" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-card>
      </DescriptionList>
      <a-divider dashed />
      <DescriptionList
        title="基本信息"
        size="large"
        style="margin-bottom: 15px; "
      >
        <a-card :bordered="false" style="margin-left: -16px">
          <div class="table-operator">
            <a-form layout="inline" :form="baseDetailForm">
              <a-row :gutter="5" style="line-height: 4">
                <a-col :md="12" :sm="24">
                  <a-form-item label="首次开业日期">
                    <a-date-picker
                      v-decorator="[
                        'basicInfo.openDate',
                        {
                          rules: [
                            {
                              required: false,
                              message: '请选择日期!'
                            }
                          ],
                          initialValue: baseInfo.openDateLg
                            ? moment(baseInfo.openDateLg)
                            : ''
                        }
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="营业时间">
                    <a-time-picker
                      format="h:mm a"
                      style="width: 110px"
                      v-decorator="[
                        'basicInfo.businessHourStart',
                        {
                          rules: [
                            {
                              required: false,
                              message: '请选择'
                            }
                          ],
                          initialValue: baseInfo.businessHourStartLg
                            ? moment(baseInfo.businessHourStartLg)
                            : ''
                        }
                      ]"
                    />
                    —
                    <a-time-picker
                      format="h:mm a"
                      style="width: 110px"
                      v-decorator="[
                        'basicInfo.businessHourEnd',
                        {
                          rules: [
                            {
                              required: false,
                              message: '请选择'
                            }
                          ],
                          initialValue: baseInfo.businessHourEndLg
                            ? moment(baseInfo.businessHourEndLg)
                            : ''
                        }
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="大楼类型">
                    <a-input
                      style="width: 240px"
                      disabled
                      v-decorator="[
                        'buildingType',
                        {
                          rules: [
                            {
                              message: '请输入'
                            }
                          ],
                          initialValue: baseInfo.buildingType
                        }
                      ]"
                      placeholder="无"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="周边类型">
                    <a-select
                      style="width: 240px"
                      disabled
                      v-decorator="[
                        'neighborhoodType',
                        {
                          rules: [
                            {
                              message: '请选择'
                            }
                          ],
                          initialValue: baseInfo.neighborhoodType
                        }
                      ]"
                      placeholder="无"
                    >
                      <a-select-option v-for="item in storeType" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="是否沿街店铺">
                    <a-select
                      style="width: 240px"
                      disabled
                      v-decorator="[
                        'streetAccess',
                        {
                          rules: [
                            {
                              message: '请选择'
                            }
                          ],
                          initialValue: baseInfo.streetAccess
                        }
                      ]"
                      placeholder="无"
                    >
                      <a-select-option v-for="item in yesOrNo" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="是否有扶梯">
                    <a-select
                      disabled
                      style="width: 240px"
                      v-decorator="[
                        'escalatorAccess',
                        {
                          rules: [
                            {
                              message: '请选择'
                            }
                          ],
                          initialValue: baseInfo.escalatorAccess
                        }
                      ]"
                      placeholder="无"
                    >
                      <a-select-option v-for="item in yesOrNo" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="是否有电梯">
                    <a-select
                      disabled
                      style="width: 240px"
                      v-decorator="[
                        'elevatorAccess',
                        {
                          rules: [
                            {
                              message: '请选择'
                            }
                          ],
                          initialValue: baseInfo.elevatorAccess
                        }
                      ]"
                      placeholder="无"
                    >
                      <a-select-option v-for="item in yesOrNo" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="是否有楼梯">
                    <a-select
                      disabled
                      style="width: 240px"
                      v-decorator="[
                        'stairsAccess',
                        {
                          rules: [
                            {
                              message: '请选择'
                            }
                          ],
                          initialValue: baseInfo.stairsAccess
                        }
                      ]"
                      placeholder="无"
                    >
                      <a-select-option v-for="item in yesOrNo" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="地产负责人">
                    <a-input
                      style="width: 240px"
                      disabled
                      v-decorator="[
                        'buildingType',
                        {
                          rules: [
                            {
                              message: '请输入'
                            }
                          ],
                          initialValue: baseInfo.acquisitionsOwner
                        }
                      ]"
                      placeholder="无"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="邮政编码">
                    <a-input
                      style="width: 240px"
                      disabled
                      v-decorator="[
                        'postCode',
                        {
                          rules: [
                            {
                              message: '请输入'
                            }
                          ],
                          initialValue: baseInfo.postCode
                        }
                      ]"
                      placeholder="无"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="场地合同面积">
                    <a-input
                      style="width: 240px"
                      disabled
                      v-decorator="[
                        'buildingType',
                        {
                          rules: [
                            {
                              message: '请输入'
                            }
                          ],
                          initialValue: buildingInfo.buildingGrossArea
                        }
                      ]"
                      placeholder="无"
                    />m³
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="营业执照范围">
                    <a-select
                      style="min-width: 607px"
                      mode="multiple"
                      v-decorator="[
                        'basicInfo.bizLicenseScope',
                        {
                          rules: [
                            {
                              required: false,
                              message: '请选择'
                            }
                          ],
                          initialValue: this.changeSelect(
                            baseInfo.bizLicenseScopeIdList
                          )
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in bizLicenseScope"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24">
                  <a-form-item label="食品经营许可证范围">
                    <a-select
                      mode="multiple"
                      style="min-width: 607px"
                      v-decorator="[
                        'basicInfo.cateringLicenseScope',
                        {
                          rules: [
                            {
                              required: false,
                              message: '请选择'
                            }
                          ],
                          initialValue: this.changeSelect(
                            baseInfo.cateringLicenseScopeIdList
                          )
                        }
                      ]"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in cateringLicenseScope"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-card>
      </DescriptionList>
      <div style="margin-top: 8px">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="cancelChange">
              取消
            </a-button>
            <a-button
              type="primary"
              style="margin-left: 8px"
              @click="saveBaseInfo"
            >
              保存
            </a-button>
          </div>
        </a-card>
      </div>
      <a-divider />
      <DescriptionList
        title="建筑信息"
        size="large"
        style="margin-bottom: 15px; "
      >
        <a-card :bordered="false" style="margin-left: -16px;">
          <a-form layout="inline" :form="facilityBuildingForm">
            <a-row :gutter="5" style="line-height: 4">
              <a-col :md="12" :sm="24">
                <a-form-item label="门店设计面积">
                  <a-input
                    style="width: 240px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.nfa',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            required: true,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.nfa
                      }
                    ]"
                    placeholder="请输入"
                  />
                  ㎡
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="档口可出租面积">
                  <a-input
                    style="width: 240px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.totalKitchenUsableArea',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            required: true,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.totalKitchenUsableArea
                      }
                    ]"
                    placeholder="请输入"
                  />
                  ㎡
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="门店电量扩容费">
                  <a-input
                    :disabled="isREP"
                    style="width: 240px"
                    v-decorator="[
                      'facilityBuildingDTO.facilityElectricityUpgradeCost',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue:
                          buildingInfo.facilityElectricityUpgradeCost
                      }
                    ]"
                    placeholder="请输入"
                  />
                  元
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="门店排烟扩容费">
                  <a-input
                    style="width: 240px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.facilityExhaustUpgradeCost',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.facilityExhaustUpgradeCost
                      }
                    ]"
                    placeholder="请输入"
                  />
                  元
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="门店燃气扩容费">
                  <a-input
                    style="width: 240px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.facilityGasUpgradeCost',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.facilityGasUpgradeCost
                      }
                    ]"
                    placeholder="请输入"
                  />
                  元
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="门店空调扩容费">
                  <a-input
                    style="width: 240px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.facilityAcUpgradeCost',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.facilityAcUpgradeCost
                      }
                    ]"
                    placeholder="请输入"
                  />
                  元
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                <a-form-item label="门店扩容前电费总量">
                  <a-input
                    :disabled="isREP"
                    style="width: 198px"
                    v-decorator="[
                      'facilityBuildingDTO.facilityExistingElectricity',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.facilityExistingElectricity
                      }
                    ]"
                    placeholder="请输入"
                  />
                  Kw
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                <a-form-item label="门店扩容前排烟量">
                  <a-input
                    style="width: 198px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.facilityExistingExhaust',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.facilityExistingExhaust
                      }
                    ]"
                    placeholder="请输入"
                  />
                  ㎡/h
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                <a-form-item label="门店扩容前燃气量">
                  <a-input
                    style="width: 198px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.facilityExistingGas',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.facilityExistingGas
                      }
                    ]"
                    placeholder="请输入"
                  />
                  ㎡
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                <a-form-item label="门店扩容后总电量">
                  <a-input
                    style="width: 198px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.facilityPostUpgradeElectricity',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue:
                          buildingInfo.facilityPostUpgradeElectricity
                      }
                    ]"
                    placeholder="请输入"
                  />
                  Kw
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                <a-form-item label="门店扩容后排烟量">
                  <a-input
                    style="width: 198px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.facilityPostUpgradeExhaust',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.facilityPostUpgradeExhaust
                      }
                    ]"
                    placeholder="请输入"
                  />
                  ㎡/h
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                <a-form-item label="门店扩容后燃气量">
                  <a-input
                    style="width: 198px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.facilityPostUpgradeGas',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.facilityPostUpgradeGas
                      }
                    ]"
                    placeholder="请输入"
                  />
                  ㎡
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24" class="storeNewStoreLabelWidth2">
                <a-form-item label="门店扩容前空调量">
                  <a-input
                    style="width: 198px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.facilityExistingAc',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.facilityExistingAc
                      }
                    ]"
                    placeholder="请输入"
                  />
                  W
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24" class="storeNewStoreLabelWidth2">
                <a-form-item label="门店扩容后空调量">
                  <a-input
                    style="width: 198px"
                    :disabled="isREP"
                    v-decorator="[
                      'facilityBuildingDTO.facilityPostUpgradeAc',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            message: '请输入'
                          }
                        ],
                        initialValue: buildingInfo.facilityPostUpgradeAc
                      }
                    ]"
                    placeholder="请输入"
                  />
                  W
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="5" style="line-height: 8;margin-top:18px;">
              <a-col :md="12" :sm="24">
                <a-form-item
                  label="平面规划图"
                  class="fileBox"
                  :labelCol="{ span: 24 }"
                >
                  <div class="imgBox">
                    <div
                      v-for="(item, index) in floorPlanMedias.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <img
                        slot="cover"
                        alt="example"
                        @click="viewImg(item.url)"
                        :src="item.url"
                        class="image-edit"
                      />
                      <span
                        class="remove"
                        @click="removeFile(item, 'floorPlanMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="otherBox">
                    <div
                      v-for="(item, index) in floorPlanMedias.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <a target="_blank" :href="item.url" :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                      <span
                        class="remove"
                        @click="removeFile(item, 'floorPlanMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                  <CompressUpload :onFinish="onBuildingUploadFinish" />
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item
                  label="门店3D渲染图"
                  class="fileBox"
                  :labelCol="{ span: 24 }"
                >
                  <div class="imgBox">
                    <div
                      v-for="(item, index) in designRenderMedias.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <img
                        slot="cover"
                        alt="example"
                        @click="viewImg(item.url)"
                        :src="item.url"
                        class="image-edit"
                      />
                      <span
                        class="remove"
                        @click="removeFile(item, 'designRenderMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="otherBox">
                    <div
                      v-for="(item, index) in designRenderMedias.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <a target="_blank" :href="item.url" :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                      <span
                        class="remove"
                        @click="removeFile(item, 'designRenderMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                   <CompressUpload :onFinish="on3DUploadFinish" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
        <div style="margin-top: 8px">
          <a-card :bordered="false">
            <div class="steps-action">
              <a-button @click="cancelChange">
                取消
              </a-button>
              <a-button
                type="primary"
                style="margin-left: 8px"
                @click="saveBuildingInfo"
              >
                保存
              </a-button>
            </div>
          </a-card>
        </div>
      </DescriptionList>
    </a-spin>
    <!--  -->
    <!-- 弹窗 -->
    <!-- <a-modal :width="1000" v-model="visibleImg" title="图片详情" :footer="null">
      <img style="width:100%;height:100%;" :src="showImgUrl" />
    </a-modal> -->
    <ImagePreviewModal v-if="visibleImg" :imageUrls="showImgUrl" :closeAction="hideImageModal" />
  </div>
</template>

<script>
import _get from "lodash/get";
import moment from "moment";
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
  
import CompressUpload from "@/components/CompressUpload";
import ImagePreviewModal from "@/components/ImagePreviewModal";

export default {
  name: "BaseDetailEdit",
  props: {
    // 基础信息
    baseInfo: {},
    // 基础信息图
    facilityMedias: {},
    // 建筑信息
    buildingInfo: {},
    // 信息平面图
    floorPlanMedias: {},
    // 设计平面图
    designRenderMedias: {},
    //
    initPage: {
      type: Function,
      default: null
    },
    isREP: {
      type: Boolean,
      default: true
    }
  },
  components: {
    DescriptionList,
    CompressUpload,
    ImagePreviewModal,
  },
  data() {
    return {
      // 基本信息
      baseDetailForm: this.$form.createForm(this),
      // 建筑信息
      facilityBuildingForm: this.$form.createForm(this),
      //是否
      yesOrNo: [
        {
          id: "Y",
          name: "是"
        },
        {
          id: "N",
          name: "否"
        }
      ],
      //门店类型
      storeType: [
        {
          id: 1,
          name: "纯外卖"
        },
        {
          id: 2,
          name: "外卖+堂食"
        },
        {
          id: 3,
          name: "外卖+大餐厅商户"
        },
        {
          id: 4,
          name: "外卖+高端食堂"
        },
        {
          id: 5,
          name: "外卖+食堂"
        },
        {
          id: 6,
          name: "其他"
        }
      ],
      //门店性质
      storeNature: [
        {
          id: 1,
          name: "自建"
        },
        {
          id: 2,
          name: "美广收购"
        },
        {
          id: 3,
          name: "承包经营"
        }
      ],
      //营业执照经营范围
      bizLicenseScope: [
        {
          id: "1",
          name: "餐饮企业管理"
        },
        {
          id: "2",
          name: "会务服务"
        },
        {
          id: "3",
          name: "展览展示服务"
        },
        {
          id: "4",
          name: "餐饮服务"
        },
        {
          id: "5",
          name: "清洁服务"
        },
        {
          id: "6",
          name: "礼仪服务"
        }
      ],
      //食品经营许可证范围
      cateringLicenseScope: [
        {
          id: "1",
          name: "热食类食品制售"
        },
        {
          id: "2",
          name: "冷食类食品制售"
        },
        {
          id: "3",
          name: "生食类食品制售（即生食类食品）"
        },
        {
          id: "4",
          name: "自制饮品"
        },
        {
          id: "5",
          name: "半成品"
        },
        {
          id: "6",
          name: "预包装"
        }
      ],
      // 城市列表
      cityList: {},
      // 品牌
      brandNameList: [],
      // 查看图片
      showImgUrl: "",
      visibleImg: false,
      // basicLoading
      basicLoading: false
    };
  },
  watch: {
    baseInfo: {
      deep: true,
      handler(val, oVal) {
        if (!!val.cityId) {
          this.getCityList();
          this.getKitchenList(val.cityId);
        }
      }
    }
  },
  created() {
    if (this.baseInfo.cityId) {
      this.getCityList();
      this.getKitchenList(this.baseInfo.cityId);
    }
  },
  mounted() {},
  computed: {},
  methods: {
    // 取消
    cancelChange() {
      this.$router.push({ path: "/store/storeList" });
    },
    // pushArr()
    pushArr(arr, url) {
      let ra = [];
      let obj = {
        fType: this.checkURLType(url),
        url: url
      };
      if (!arr) {
        arr = [].push(obj);
      } else {
        arr = arr.push(obj);
      }
    },
    // 判断图片
    checkURLType(item) {
      if (/\.(jpg|jpeg|png|gif|GIF|JPG|PNG)$/.test(item)) {
        return "img";
      } else if(/\.(pdf|PDF)$/.test(item)) {
        return "pdf";
      } else {
        return "other";
      }
    },
    // 查看照片
    viewImg(url) {
      this.showImgUrl = url || "";
      this.visibleImg = true;
    },
    // 获取城市列表
    getCityList() {
      axios({
        url:   this.$baseUrl + "/city/listLoginCity",
        method: "GET",
        withCredentials: true
      }).then(res => {
        if (res.data.returnCode == 200) {
          this.cityList = res.data.rows;
        }
      });
    },
    // 改变城市列表
    selectCity(val) {
      this.brandNameList = [];
      this.getKitchenList(val);
      const {
        baseDetailForm: { setFieldsValue }
      } = this;
      setFieldsValue({
        facilityBrand: ""
      });
    },
    // 根据城市获取厨房品牌
    getKitchenList(val) {
      axios({
        url: "facilityBrand/select",
        method: "GET",
        params: {
          cityId: val
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.brandNameList = res.data.rows;
          this.brandNameList.forEach(item => {
            item.code = Number(item.code);
          });
        }
      });
    },
    removeFile(item, name) {
      this[name].forEach((ii, index) => {
        if (ii.url == item.url) {
          this[name].splice(index, 1);
        }
      });
    },
    moment,
    // 脏数据
    changeSelect(val) {
      return val ? val : undefined;
    },
    // 获取上传url
    uploadUrl() {
      return   this.$baseUrl + "upload/ossFile";
    },
    // 时间改为当天的时间戳
    changeNowTime(val) {
      let newTime = new Date(val);
      let nowTime = new Date();
      let y = nowTime.getFullYear();
      let m = nowTime.getMonth();
      let d = nowTime.getDate();
      newTime.setFullYear(y);
      newTime.setMonth(m);
      newTime.setDate(d);
      return newTime;
    },
    getUrlArr(list) {
      if (!list) {
        return [];
      }
      let arr = [];
      list.forEach(item => {
        arr.push(item.url);
      });
      return arr;
    },
    //  提交
    saveBaseInfo() {
      this.basicLoading = true;
      const {
        baseDetailForm: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          this.submitBaseInfo(values);
        } else {
          this.basicLoading = false;
        }
      });
    },
    //修改基本信息
    submitBaseInfo(val) {
      let facilityMedias = this.getUrlArr(this.facilityMedias);
      axios({
        url:   this.$baseUrl + "/facility/modify/basic",
        method: "POST",
        withCredentials: true,
        data: {
          ...val,
          id: this.baseInfo.id,
          step: 1,
          facilityMediaUrls: facilityMedias
        }
      })
        .then(res => {
          if (res.data.returnCode == "200") {
            this.initPage();
            this.$message.success("保存成功！");
          } else {
            this.$message.error("保存失败");
          }
          this.basicLoading = false;
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    //修改建筑信息
    saveBuildingInfo() {
      this.basicLoading = true;
      const {
        facilityBuildingForm: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          this.submitBuildingInfo(values);
        } else {
          this.basicLoading = false;
        }
      });
    },
    // submitBuildingInfo
    submitBuildingInfo(val) {
      let floorPlanMedias = this.getUrlArr(this.floorPlanMedias);
      let designRenderMedias = this.getUrlArr(this.designRenderMedias);
      val.facilityBuildingDTO.facilityFloorPlanMediaUrls = floorPlanMedias;
      val.facilityBuildingDTO.facilityDesignRenderMediaUrls = designRenderMedias;
      axios({
        url:   this.$baseUrl + "facility/modify/building",
        method: "POST",
        withCredentials: true,
        data: {
          ...val,
          id: this.baseInfo.id,
          step: 1
        }
      })
        .then(res => {
          if (res.data.returnCode == "200") {
            this.initPage();
            this.$message.success("保存成功！");
          } else {
            this.$message.error("保存失败");
          }
          this.basicLoading = false;
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    onFacilityUploadFinish(res) {
      this.onFinish(res, this.facilityMedias)
    },
    onBuildingUploadFinish(res) {
      this.onFinish(res, this.floorPlanMedias)
    },
    on3DUploadFinish(res) {
      this.onFinish(res, this.designRenderMedias)
    },
    onFinish(res, list){
      if(_get(res, 'data.returnCode') !== '200') {
        this.$message.error(`上传失败！${_get(res, 'data.returnMsg')}`);
      } else {
        this.$message.success("上传成功");
        this.pushArr(list, res.data.obj);
      }
    },
    hideImageModal() {
      this.visibleImg = false;
    },
  }
};
</script>
<style></style>
<style lang="less" scoped>
.p18 {
  padding: 18px;
}
.mt18 {
  margin-top: 18px;
}
</style>
