<template>
  <div style="padding:0;">
    <a-spin :spinning="dataLoading">
      <DescriptionList title="证照信息" size="large">
        <a-card :bordered="false" style="margin-left: -16px; ">
          <a-form layout="inline" :form="licenceDetailForm">
            <a-row :gutter="5" style="line-height: 4">
              <a-col :md="24" :sm="24">
                <a-form-item label="门店主体名称">
                  <a-input
                    style="min-width: 240px"
                    v-decorator="[
                      'facilityCertificatesDTO.facilityEntity',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.entity
                      }
                    ]"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="营业期限">
                  <a-range-picker
                    v-decorator="[
                      'facilityCertificatesDTO.bizLicenseDate',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择'
                          }
                        ],
                        initialValue: [
                          baseInfo.bizLicenseStartDateLg
                            ? moment(baseInfo.bizLicenseStartDateLg)
                            : '',
                          baseInfo.bizLicenseEndDateLg
                            ? moment(baseInfo.bizLicenseEndDateLg)
                            : ''
                        ]
                      }
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="统一社会信用代码">
                  <a-input
                    style="min-width: 240px"
                    v-decorator="[
                      'facilityCertificatesDTO.socialCode',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.socialCode
                      }
                    ]"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="营业执照范围">
                  <a-select
                    style="min-width: 400px"
                    mode="multiple"
                    v-decorator="[
                      'facilityCertificatesDTO.bizLicenseScope',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择!'
                          }
                        ],
                        initialValue: this.changeSelect(
                          baseInfo.bizLicenseScopeIdList
                        )
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in bizLicenseScope"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-divider dashed />
              <a-col :md="24" :sm="24">
                <a-form-item label="FDA食药监是否通过">
                  <a-select
                    style="min-width: 200px"
                    v-decorator="[
                      'facilityCertificatesDTO.fdaStatus',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择'
                          }
                        ],
                        initialValue: baseInfo.fdaStatus
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="食品经营许可证">
                  <a-range-picker
                    v-decorator="[
                      'facilityCertificatesDTO.foodCertificateDate',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择!'
                          }
                        ],
                        initialValue: [
                          baseInfo.foodCertificateStartDateLg
                            ? moment(baseInfo.fdaPermitStartDateLg)
                            : '',
                          baseInfo.foodCertificateEndDateLg
                            ? moment(baseInfo.fdaPermitEndDateLg)
                            : ''
                        ]
                      }
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="食品经营许可证编码">
                  <a-input
                    style="min-width: 200px"
                    v-decorator="[
                      'facilityCertificatesDTO.foodCertificate',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.foodCertificate
                      }
                    ]"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="食品经营许可证范围">
                  <a-select
                    style="min-width: 400px"
                    mode="multiple"
                    v-decorator="[
                      'facilityCertificatesDTO.cateringLicenseScope',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择!'
                          }
                        ],
                        initialValue: this.changeSelect(
                          baseInfo.cateringLicenseScopeIdList
                        )
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in cateringLicenseScope"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-divider dashed />
              <a-col :md="8" :sm="24">
                <a-form-item label="FSB消防验收通过">
                  <a-select
                    style="min-width: 200px"
                    v-decorator="[
                      'facilityCertificatesDTO.fsbStatus',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择'
                          }
                        ],
                        initialValue: baseInfo.fsbStatus
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="消防许可通过日期">
                  <a-date-picker
                    style="min-width: 200px"
                    v-decorator="[
                      'facilityCertificatesDTO.fsbPassDate',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择日期!'
                          }
                        ],
                        initialValue: baseInfo.fsbPassDateLg
                          ? moment(baseInfo.fsbPassDateLg)
                          : ''
                      }
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否有大消防证">
                  <a-select
                    style="min-width: 200px"
                    v-decorator="[
                      'facilityCertificatesDTO.fireCertificate',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择'
                          }
                        ],
                        initialValue: buildingInfo.fireCertificate
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="消防许可证编号">
                  <a-input
                    style="min-width: 200px"
                    v-decorator="[
                      'facilityCertificatesDTO.fsbCode',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.fsbCode
                      }
                    ]"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否有环评备案">
                  <a-select
                    style="min-width: 200px"
                    v-decorator="[
                      'facilityCertificatesDTO.environmentAssessment',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择'
                          }
                        ],
                        initialValue: buildingInfo.environmentAssessment
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="16" :sm="24">
                <a-form-item label="是否有红头文件">
                  <a-select
                    style="min-width: 200px"
                    v-decorator="[
                      'facilityCertificatesDTO.governmentOfficialLetter',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择'
                          }
                        ],
                        initialValue: buildingInfo.governmentOfficialLetter
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </DescriptionList>
      <a-divider />
      <DescriptionList size="large">
        <a-card :bordered="false" style="margin-left: -16px;">
          <a-form layout="inline" :form="licenceDetailForm">
            <a-row :gutter="5" style="line-height: 8">
              <a-col :md="24" :sm="24">
                <a-form-item
                  label="营业执照图片"
                  class="fileBox"
                  :labelCol="{ span: 24 }"
                >
                  <div class="imgBox">
                    <div
                      v-for="(item, index) in bizLicenseMedias.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <img
                        slot="cover"
                        alt="example"
                        @click="viewImg($event)"
                        :src="item.url"
                      />
                      <span
                        class="remove"
                        @click="removeFile(item, 'bizLicenseMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="otherBox">
                    <div
                      v-for="(item, index) in bizLicenseMedias.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <a target="_blank" :href="item.url" :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                      <span
                        class="remove"
                        @click="removeFile(item, 'bizLicenseMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="uploadBox">
                    <a-upload
                      withCredentials
                      list-type="picture-card"
                      :action="this.uploadUrl()"
                      name="multipartFile"
                      @change="uploadBizLicensePhotoFile"
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传</div>
                      </div>
                    </a-upload>
                  </div>
                </a-form-item>
              </a-col>
              <a-divider dashed />
              <a-col :md="12" :sm="24">
                <a-form-item
                  label="食品经营许可证照片"
                  class="fileBox"
                  :labelCol="{ span: 24 }"
                >
                  <div class="imgBox">
                    <div
                      v-for="(item, index) in foodCertificateMedias.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <img
                        slot="cover"
                        alt="example"
                        @click="viewImg($event)"
                        :src="item.url"
                      />
                      <span
                        class="remove"
                        @click="removeFile(item, 'foodCertificateMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="otherBox">
                    <div
                      v-for="(item, index) in foodCertificateMedias.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <a target="_blank" :href="item.url" :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                      <span
                        class="remove"
                        @click="removeFile(item, 'foodCertificateMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="uploadBox">
                    <a-upload
                      withCredentials
                      list-type="picture-card"
                      :action="this.uploadUrl()"
                      name="multipartFile"
                      @change="uploadFoodCertificateFile"
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传</div>
                      </div>
                    </a-upload>
                  </div>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item
                  label="食药监平面图图片"
                  class="fileBox"
                  :labelCol="{ span: 24 }"
                >
                  <div class="imgBox">
                    <div
                      v-for="(item, index) in fdaFloorPlanMedias.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <img
                        slot="cover"
                        alt="example"
                        @click="viewImg($event)"
                        :src="item.url"
                      />
                      <span
                        class="remove"
                        @click="removeFile(item, 'fdaFloorPlanMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="otherBox">
                    <div
                      v-for="(item, index) in fdaFloorPlanMedias.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <a target="_blank" :href="item.url" :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                      <span
                        class="remove"
                        @click="removeFile(item, 'fdaFloorPlanMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="uploadBox">
                    <a-upload
                      withCredentials
                      list-type="picture-card"
                      :action="this.uploadUrl()"
                      name="multipartFile"
                      @change="uploadFdaFloorPlanFile"
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传</div>
                      </div>
                    </a-upload>
                  </div>
                </a-form-item>
              </a-col>
              <a-divider dashed />
              <a-col :md="8" :sm="24">
                <a-form-item
                  label="食药监平面图图片"
                  class="fileBox"
                  :labelCol="{ span: 24 }"
                >
                  <div class="imgBox">
                    <div
                      v-for="(item, index) in completionMediaUrls.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <img
                        slot="cover"
                        alt="example"
                        @click="viewImg($event)"
                        :src="item.url"
                      />
                      <span
                        class="remove"
                        @click="removeFile(item, 'completionMediaUrls')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="otherBox">
                    <div
                      v-for="(item, index) in completionMediaUrls.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <a target="_blank" :href="item.url" :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                      <span
                        class="remove"
                        @click="removeFile(item, 'completionMediaUrls')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="uploadBox">
                    <a-upload
                      withCredentials
                      list-type="picture-card"
                      :action="this.uploadUrl()"
                      name="multipartFile"
                      @change="uploadCompletionFile"
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传</div>
                      </div>
                    </a-upload>
                  </div>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item
                  label="消防验收意见书(备案)"
                  class="fileBox"
                  :labelCol="{ span: 24 }"
                >
                  <div class="imgBox">
                    <div
                      v-for="(item, index) in fsbMedias.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <img
                        slot="cover"
                        alt="example"
                        @click="viewImg($event)"
                        :src="item.url"
                      />
                      <span
                        class="remove"
                        @click="removeFile(item, 'fsbMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="otherBox">
                    <div
                      v-for="(item, index) in fsbMedias.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <a target="_blank" :href="item.url" :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                      <span
                        class="remove"
                        @click="removeFile(item, 'fsbMedias')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="uploadBox">
                    <a-upload
                      withCredentials
                      list-type="picture-card"
                      :action="this.uploadUrl()"
                      name="multipartFile"
                      @change="uploadFsbMediasFile"
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传</div>
                      </div>
                    </a-upload>
                  </div>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item
                  label="消防安全检查合格证"
                  class="fileBox"
                  :labelCol="{ span: 24 }"
                >
                  <div class="imgBox">
                    <div
                      v-for="(item, index) in fsbSecurityCheckPhoto.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <img
                        slot="cover"
                        alt="example"
                        @click="viewImg($event)"
                        :src="item.url"
                      />
                      <span
                        class="remove"
                        @click="removeFile(item, 'fsbSecurityCheckPhoto')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="otherBox">
                    <div
                      v-for="(item, index) in fsbSecurityCheckPhoto.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <a target="_blank" :href="item.url" :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                      <span
                        class="remove"
                        @click="removeFile(item, 'fsbSecurityCheckPhoto')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="uploadBox">
                    <a-upload
                      withCredentials
                      list-type="picture-card"
                      :action="this.uploadUrl()"
                      name="multipartFile"
                      @change="uploadFsbSecurityCheckFile"
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传</div>
                      </div>
                    </a-upload>
                  </div>
                </a-form-item>
              </a-col>
              <a-divider dashed />
              <a-col :md="24" :sm="24">
                <a-form-item
                  label="其他资料"
                  class="fileBox"
                  :labelCol="{ span: 24 }"
                >
                  <div class="imgBox">
                    <div
                      v-for="(item, index) in otherMediaUrls.filter(
                        item => item.fType == 'img'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <img
                        slot="cover"
                        alt="example"
                        @click="viewImg($event)"
                        :src="item.url"
                      />
                      <span
                        class="remove"
                        @click="removeFile(item, 'otherMediaUrls')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="otherBox">
                    <div
                      v-for="(item, index) in otherMediaUrls.filter(
                        item => item.fType == 'other'
                      )"
                      :key="index"
                      class="imgItem"
                    >
                      <a target="_blank" :href="item.url" :download="item.url"
                        >[附件{{ index + 1 }}]</a
                      >
                      <span
                        class="remove"
                        @click="removeFile(item, 'otherMediaUrls')"
                        >X</span
                      >
                    </div>
                  </div>
                  <div class="uploadBox">
                    <a-upload
                      withCredentials
                      list-type="picture-card"
                      :action="this.uploadUrl()"
                      name="multipartFile"
                      @change="uploadOtherMediaUrlsFile"
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传</div>
                      </div>
                    </a-upload>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </DescriptionList>
      <div style="margin-top: 8px">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="cancelChange">
              取消
            </a-button>
            <a-button
              type="primary"
              style="margin-left: 8px"
              @click="saveLicenceInfo"
            >
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </a-spin>
    <!-- 弹窗 -->
    <a-modal :width="1000" v-model="visibleImg" title="图片详情" :footer="null">
      <img style="width:100%;height:100%;" :src="showImgUrl" />
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import DescriptionList from "@/components/DescriptionList";
  
export default {
  name: "LicenceDetailEdit",
  props: {
    // 权限信息
    // hasRight:{},
    // 基本信息
    baseInfo: {},
    buildingInfo: {},
    // 营业执照图片
    bizLicenseMedias: {},
    // foodCertificateMedias 食品经营许可证图片
    foodCertificateMedias: {},
    // 食药监平面图图片
    fdaFloorPlanMedias: {},
    // completionMediaUrls
    completionMediaUrls: {},
    // 消防许可证图片
    fsbMedias: {},
    // fsbSecurityCheckPhoto
    fsbSecurityCheckPhoto: {},
    // otherMediaUrls
    otherMediaUrls: {},
    //
    initPage: {
      type: Function,
      default: null
    }
  },
  components: {
    DescriptionList
  },
  data() {
    return {
      // 弹窗
      showImgUrl: "",
      visibleImg: false,
      //
      licenceDetailForm: this.$form.createForm(this),
      //是否
      yesOrNo: [
        {
          id: "Y",
          name: "是"
        },
        {
          id: "N",
          name: "否"
        }
      ],
      //营业执照经营范围
      bizLicenseScope: [
        {
          id: "1",
          name: "餐饮企业管理"
        },
        {
          id: "2",
          name: "会务服务"
        },
        {
          id: "3",
          name: "展览展示服务"
        },
        {
          id: "4",
          name: "餐饮服务"
        },
        {
          id: "5",
          name: "清洁服务"
        },
        {
          id: "6",
          name: "礼仪服务"
        }
      ],
      //食品经营许可证范围
      cateringLicenseScope: [
        {
          id: "1",
          name: "热食类食品制售"
        },
        {
          id: "2",
          name: "冷食类食品制售"
        },
        {
          id: "3",
          name: "生食类食品制售（即生食类食品）"
        },
        {
          id: "4",
          name: "自制饮品"
        },
        {
          id: "5",
          name: "半成品"
        },
        {
          id: "6",
          name: "预包装"
        }
      ],
      // dataLoading
      dataLoading: false
    };
  },
  watch: {},
  filters: {
    // 营业执照范围
    showbizLicenseScopeIdList(val) {
      switch (val) {
        case "1":
          return "餐饮企业管理";
          break;
        case "2":
          return "会务服务";
          break;
        case "3":
          return "展览展示服务";
          break;
        case "4":
          return "餐饮服务";
          break;
        case "5":
          return "清洁服务";
          break;
        case "6":
          return "礼仪服务";
          break;
      }
    },
    // 食品经营许可证范围
    showCateringLicenseScopeIdList(val) {
      switch (val) {
        case "1":
          return "热食类食品制售";
          break;
        case "2":
          return "冷食类食品制售";
          break;
        case "3":
          return "生食类食品制售（即生食类食品）";
          break;
        case "4":
          return "自制饮品";
          break;
        case "5":
          return "半成品";
          break;
        case "6":
          return "预包装";
          break;
      }
    }
  },
  created() {},
  computed: {},
  methods: {
    // 判断图片
    checkURLType(item) {
      if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(item)) {
        return "img";
      } else {
        return "other";
      }
    },
    // 获取上传url
    uploadUrl() {
      return   this.$baseUrl + "upload/ossFile";
    },
    removeFile(item, name) {
      this[name].forEach((ii, index) => {
        if (ii.url == item.url) {
          this[name].splice(index, 1);
        }
      });
    },
    // 取消
    cancelChange() {
      this.$router.push({ path: "/store/storeList" });
    },
    // pushArr()
    pushArr(arr, url) {
      let ra = [];
      let obj = {
        fType: this.checkURLType(url),
        url: url
      };
      if (!arr) {
        arr = [].push(obj);
      } else {
        arr = arr.push(obj);
      }
    },
    moment,
    // 查看照片
    viewImg(e) {
      this.showImgUrl = e.target.src || "";
      this.visibleImg = true;
    },
    // 返回日期格式
    changeTimeStyle(time) {
      return time ? moment(time).format("YYYY-MM-DD") : "";
    },
    // 选择
    changeSelect(val) {
      return val ? val : undefined;
    },
    // uploadBizLicensePhotoFile
    uploadBizLicensePhotoFile({ fileList }) {
      this.dataLoading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            this.dataLoading = false;
            if (element.response.returnCode == "200") {
              this.pushArr(this.bizLicenseMedias, element.response.obj);
              fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              return;
            }
          }
        });
      }
    },
    // uploadFoodCertificateFile
    uploadFoodCertificateFile({ fileList }) {
      this.dataLoading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            this.dataLoading = false;
            if (element.response.returnCode == "200") {
              this.pushArr(this.foodCertificateMedias, element.response.obj);
              fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    // uploadFdaFloorPlanFile
    uploadFdaFloorPlanFile({ fileList }) {
      this.dataLoading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            this.dataLoading = false;
            if (element.response.returnCode == "200") {
              this.pushArr(this.fdaFloorPlanMedias, element.response.obj);
              fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    // uploadCompletionFile
    uploadCompletionFile({ fileList }) {
      this.dataLoading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            this.dataLoading = false;
            if (element.response.returnCode == "200") {
              this.pushArr(this.completionMediaUrls, element.response.obj);
              fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    // uploadFsbMediasFile
    uploadFsbMediasFile({ fileList }) {
      this.dataLoading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            this.dataLoading = false;
            if (element.response.returnCode == "200") {
              this.pushArr(this.fsbMedias, element.response.obj);
              fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    // fsbSecurityCheckPhoto
    uploadFsbSecurityCheckFile({ fileList }) {
      this.dataLoading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            this.dataLoading = false;
            if (element.response.returnCode == "200") {
              this.pushArr(this.fsbSecurityCheckPhoto, element.response.obj);
              fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    // uploadOtherMediaUrlsFile
    uploadOtherMediaUrlsFile({ fileList }) {
      this.dataLoading = true;
      if (fileList.length >= 1) {
        fileList.forEach((element, index) => {
          if (element.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
          if (element.response) {
            this.dataLoading = false;
            if (element.response.returnCode == "200") {
              this.pushArr(this.otherMediaUrls, element.response.obj);
              fileList.splice(index, 1);
            } else {
              this.$message.error("上传失败！");
              fileList.splice(index, 1);
              return;
            }
          }
        });
      }
    },
    // 保存
    saveLicenceInfo() {
      this.licenceInfoLoading = true;
      const {
        licenceDetailForm: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          this.submitLicenceInfo(values);
        } else {
          this.licenceInfoLoading = false;
        }
      });
    },
    getUrlArr(list) {
      if (!list) {
        return [];
      }
      let arr = [];
      list.forEach(item => {
        arr.push(item.url);
      });
      return arr;
    },
    // submitLicenceInfo
    submitLicenceInfo(val) {
      let bizLicenseMedias = this.getUrlArr(this.bizLicenseMedias);
      let foodCertificateMedias = this.getUrlArr(this.foodCertificateMedias);
      let fdaFloorPlanMedias = this.getUrlArr(this.fdaFloorPlanMedias);
      let completionMediaUrls = this.getUrlArr(this.completionMediaUrls);
      let fsbMedias = this.getUrlArr(this.fsbMedias);
      let fsbSecurityCheckPhoto = this.getUrlArr(this.fsbSecurityCheckPhoto);
      let otherMediaUrls = this.getUrlArr(this.otherMediaUrls);
      val.facilityCertificatesDTO.bizLicenseStartDate =
        val.facilityCertificatesDTO.bizLicenseDate[0];
      val.facilityCertificatesDTO.bizLicenseEndDate =
        val.facilityCertificatesDTO.bizLicenseDate[1];
      val.facilityCertificatesDTO.foodCertificateStartDate =
        val.facilityCertificatesDTO.foodCertificateDate[0];
      val.facilityCertificatesDTO.foodCertificateEndDate =
        val.facilityCertificatesDTO.foodCertificateDate[1];
      val.facilityCertificatesDTO.bizLicensePhoto = bizLicenseMedias;
      val.facilityCertificatesDTO.foodCertificatePhoto = foodCertificateMedias;
      val.facilityCertificatesDTO.fdaFloorPlanMediaUrls = fdaFloorPlanMedias;
      val.facilityCertificatesDTO.completionMediaUrls = completionMediaUrls;
      val.facilityCertificatesDTO.fsbPhoto = fsbMedias;
      val.facilityCertificatesDTO.fsbSecurityCheckPhoto = fsbSecurityCheckPhoto;
      val.facilityCertificatesDTO.otherMediaUrls = otherMediaUrls;
      axios({
        url:   this.$baseUrl + "facility/modify/certificate",
        method: "POST",
        withCredentials: true,
        data: {
          ...val,
          id: this.baseInfo.id,
          step: 3
        }
      })
        .then(res => {
          if (res.data.returnCode == "200") {
            this.initPage();
            this.$message.success("保存成功！");
          } else {
            this.$message.error("保存失败");
          }
          this.basicLoading = false;
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    }
  }
};
</script>
<style lang="less" scoped>
.p18 {
  padding: 18px;
}
</style>
