var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"0"}},[_c('a-spin',{attrs:{"spinning":_vm.basicLoading}},[_c('DescriptionList',{staticStyle:{"margin-bottom":"15px"},attrs:{"title":"门店信息","size":"large"}},[_c('a-card',{staticStyle:{"margin-left":"-16px"},attrs:{"bordered":false}},[_c('div',{staticClass:"table-operator"},[_c('a-form',{attrs:{"layout":"inline","form":_vm.baseDetailForm}},[_c('a-row',{staticStyle:{"line-height":"4"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店编号","label-width":"50px"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'code',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请输入'
                          }
                        ],
                        initialValue: _vm.baseInfo.code
                      }
                    ]),expression:"[\n                      'code',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: '请输入'\n                          }\n                        ],\n                        initialValue: baseInfo.code\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":_vm.isREP,"placeholder":"无"}})],1)],1),_c('a-col',{attrs:{"md":16,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'name',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请输入'
                          }
                        ],
                        initialValue: _vm.baseInfo.name
                      }
                    ]),expression:"[\n                      'name',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: '请输入'\n                          }\n                        ],\n                        initialValue: baseInfo.name\n                      }\n                    ]"}],staticStyle:{"width":"500px"},attrs:{"disabled":_vm.isREP,"placeholder":"无"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'type',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择'
                          }
                        ],
                        initialValue: _vm.baseInfo.type
                      }
                    ]),expression:"[\n                      'type',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: baseInfo.type\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":_vm.isREP,"placeholder":"请选择"}},_vm._l((_vm.storeType),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店性质"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'attribute',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择'
                          }
                        ],
                        initialValue: this.changeSelect(_vm.baseInfo.attribute)
                      }
                    ]),expression:"[\n                      'attribute',\n                      {\n                        rules: [\n                          {\n                            required: false,\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: this.changeSelect(baseInfo.attribute)\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.storeNature),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"城市"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'city',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择'
                          }
                        ],
                        initialValue: _vm.baseInfo.cityId
                      }
                    ]),expression:"[\n                      'city',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: baseInfo.cityId\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择"},on:{"change":_vm.selectCity}},_vm._l((_vm.cityList),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"所属厨房"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'facilityBrand',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择'
                          }
                        ],
                        initialValue: _vm.baseInfo.facilityBrand
                      }
                    ]),expression:"[\n                      'facilityBrand',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: baseInfo.facilityBrand\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.brandNameList),function(item){return _c('a-select-option',{key:item.code},[_vm._v(" "+_vm._s(item.optionText)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'address',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请输入'
                          }
                        ],
                        initialValue: _vm.baseInfo.address
                      }
                    ]),expression:"[\n                      'address',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: '请输入'\n                          }\n                        ],\n                        initialValue: baseInfo.address\n                      }\n                    ]"}],staticStyle:{"width":"607px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店楼层"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'floor',
                      {
                        rules: [
                          {
                            message: '请输入'
                          }
                        ],
                        initialValue: _vm.baseInfo.floor
                      }
                    ]),expression:"[\n                      'floor',\n                      {\n                        rules: [\n                          {\n                            message: '请输入'\n                          }\n                        ],\n                        initialValue: baseInfo.floor\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":"","placeholder":"无"}})],1)],1),_c('a-col',{attrs:{"md":16,"sm":24}},[_c('a-form-item',{attrs:{"label":"取餐往返时间(秒)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'roundtripTime',
                      {
                        initialValue: _vm.baseInfo.roundtripTime
                      }
                    ]),expression:"[\n                      'roundtripTime',\n                      {\n                        initialValue: baseInfo.roundtripTime\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"项目介绍"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'description',
                      {
                        rules: [
                          {
                            message: '请输入'
                          }
                        ],
                        initialValue: _vm.baseInfo.description
                      }
                    ]),expression:"[\n                      'description',\n                      {\n                        rules: [\n                          {\n                            message: '请输入'\n                          }\n                        ],\n                        initialValue: baseInfo.description\n                      }\n                    ]"}],staticStyle:{"width":"974px"},attrs:{"placeholder":"请输入"}})],1)],1)],1),_c('a-row',{staticStyle:{"line-height":"8","margin-top":"18px"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{staticClass:"fileBox",attrs:{"label":"门店照片","labelCol":{ span: 24 }}},[_c('div',{staticClass:"imgBox"},[_vm._l((_vm.facilityMedias.filter(
                        item => item.fType == 'img'
                      )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('img',{staticClass:"image-edit",attrs:{"slot":"cover","alt":"example","src":item.url},on:{"click":function($event){return _vm.viewImg(item.url)}},slot:"cover"}),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'facilityMedias')}}},[_vm._v("X")])])}),_vm._l((_vm.facilityMedias.filter(
                        item => item.fType == 'pdf'
                      )),function(item,index){return _c('div',{key:`pdf${index}`,staticClass:"imgItem"},[_c('div',{staticClass:"embed-cover",on:{"click":function($event){return _vm.viewImg(item.url)}}}),_c('embed',{staticClass:"embed-item",attrs:{"src":`${item.url}#toolbar=0&navpanes=0&scrollbar=0&view=Fit`}}),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'facilityMedias')}}},[_vm._v("X")])])})],2),_c('div',{staticClass:"otherBox"},_vm._l((_vm.facilityMedias.filter(
                        item => item.fType == 'other'
                      )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('a',{attrs:{"target":"_blank","href":item.url,"download":item.url}},[_vm._v("[附件"+_vm._s(index + 1)+"]")]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'facilityMedias')}}},[_vm._v("X")])])}),0),_c('CompressUpload',{attrs:{"onFinish":_vm.onFacilityUploadFinish}})],1)],1)],1)],1)],1)])],1),_c('a-divider',{attrs:{"dashed":""}}),_c('DescriptionList',{staticStyle:{"margin-bottom":"15px"},attrs:{"title":"基本信息","size":"large"}},[_c('a-card',{staticStyle:{"margin-left":"-16px"},attrs:{"bordered":false}},[_c('div',{staticClass:"table-operator"},[_c('a-form',{attrs:{"layout":"inline","form":_vm.baseDetailForm}},[_c('a-row',{staticStyle:{"line-height":"4"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"首次开业日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'basicInfo.openDate',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择日期!'
                          }
                        ],
                        initialValue: _vm.baseInfo.openDateLg
                          ? _vm.moment(_vm.baseInfo.openDateLg)
                          : ''
                      }
                    ]),expression:"[\n                      'basicInfo.openDate',\n                      {\n                        rules: [\n                          {\n                            required: false,\n                            message: '请选择日期!'\n                          }\n                        ],\n                        initialValue: baseInfo.openDateLg\n                          ? moment(baseInfo.openDateLg)\n                          : ''\n                      }\n                    ]"}]})],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"营业时间"}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'basicInfo.businessHourStart',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择'
                          }
                        ],
                        initialValue: _vm.baseInfo.businessHourStartLg
                          ? _vm.moment(_vm.baseInfo.businessHourStartLg)
                          : ''
                      }
                    ]),expression:"[\n                      'basicInfo.businessHourStart',\n                      {\n                        rules: [\n                          {\n                            required: false,\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: baseInfo.businessHourStartLg\n                          ? moment(baseInfo.businessHourStartLg)\n                          : ''\n                      }\n                    ]"}],staticStyle:{"width":"110px"},attrs:{"format":"h:mm a"}}),_vm._v(" — "),_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'basicInfo.businessHourEnd',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择'
                          }
                        ],
                        initialValue: _vm.baseInfo.businessHourEndLg
                          ? _vm.moment(_vm.baseInfo.businessHourEndLg)
                          : ''
                      }
                    ]),expression:"[\n                      'basicInfo.businessHourEnd',\n                      {\n                        rules: [\n                          {\n                            required: false,\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: baseInfo.businessHourEndLg\n                          ? moment(baseInfo.businessHourEndLg)\n                          : ''\n                      }\n                    ]"}],staticStyle:{"width":"110px"},attrs:{"format":"h:mm a"}})],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"大楼类型"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'buildingType',
                      {
                        rules: [
                          {
                            message: '请输入'
                          }
                        ],
                        initialValue: _vm.baseInfo.buildingType
                      }
                    ]),expression:"[\n                      'buildingType',\n                      {\n                        rules: [\n                          {\n                            message: '请输入'\n                          }\n                        ],\n                        initialValue: baseInfo.buildingType\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":"","placeholder":"无"}})],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"周边类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'neighborhoodType',
                      {
                        rules: [
                          {
                            message: '请选择'
                          }
                        ],
                        initialValue: _vm.baseInfo.neighborhoodType
                      }
                    ]),expression:"[\n                      'neighborhoodType',\n                      {\n                        rules: [\n                          {\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: baseInfo.neighborhoodType\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":"","placeholder":"无"}},_vm._l((_vm.storeType),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否沿街店铺"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'streetAccess',
                      {
                        rules: [
                          {
                            message: '请选择'
                          }
                        ],
                        initialValue: _vm.baseInfo.streetAccess
                      }
                    ]),expression:"[\n                      'streetAccess',\n                      {\n                        rules: [\n                          {\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: baseInfo.streetAccess\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":"","placeholder":"无"}},_vm._l((_vm.yesOrNo),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否有扶梯"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'escalatorAccess',
                      {
                        rules: [
                          {
                            message: '请选择'
                          }
                        ],
                        initialValue: _vm.baseInfo.escalatorAccess
                      }
                    ]),expression:"[\n                      'escalatorAccess',\n                      {\n                        rules: [\n                          {\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: baseInfo.escalatorAccess\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":"","placeholder":"无"}},_vm._l((_vm.yesOrNo),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否有电梯"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'elevatorAccess',
                      {
                        rules: [
                          {
                            message: '请选择'
                          }
                        ],
                        initialValue: _vm.baseInfo.elevatorAccess
                      }
                    ]),expression:"[\n                      'elevatorAccess',\n                      {\n                        rules: [\n                          {\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: baseInfo.elevatorAccess\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":"","placeholder":"无"}},_vm._l((_vm.yesOrNo),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否有楼梯"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'stairsAccess',
                      {
                        rules: [
                          {
                            message: '请选择'
                          }
                        ],
                        initialValue: _vm.baseInfo.stairsAccess
                      }
                    ]),expression:"[\n                      'stairsAccess',\n                      {\n                        rules: [\n                          {\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: baseInfo.stairsAccess\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":"","placeholder":"无"}},_vm._l((_vm.yesOrNo),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"地产负责人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'buildingType',
                      {
                        rules: [
                          {
                            message: '请输入'
                          }
                        ],
                        initialValue: _vm.baseInfo.acquisitionsOwner
                      }
                    ]),expression:"[\n                      'buildingType',\n                      {\n                        rules: [\n                          {\n                            message: '请输入'\n                          }\n                        ],\n                        initialValue: baseInfo.acquisitionsOwner\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":"","placeholder":"无"}})],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"邮政编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'postCode',
                      {
                        rules: [
                          {
                            message: '请输入'
                          }
                        ],
                        initialValue: _vm.baseInfo.postCode
                      }
                    ]),expression:"[\n                      'postCode',\n                      {\n                        rules: [\n                          {\n                            message: '请输入'\n                          }\n                        ],\n                        initialValue: baseInfo.postCode\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":"","placeholder":"无"}})],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"场地合同面积"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'buildingType',
                      {
                        rules: [
                          {
                            message: '请输入'
                          }
                        ],
                        initialValue: _vm.buildingInfo.buildingGrossArea
                      }
                    ]),expression:"[\n                      'buildingType',\n                      {\n                        rules: [\n                          {\n                            message: '请输入'\n                          }\n                        ],\n                        initialValue: buildingInfo.buildingGrossArea\n                      }\n                    ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":"","placeholder":"无"}}),_vm._v("m³ ")],1)],1),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"营业执照范围"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'basicInfo.bizLicenseScope',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择'
                          }
                        ],
                        initialValue: this.changeSelect(
                          _vm.baseInfo.bizLicenseScopeIdList
                        )
                      }
                    ]),expression:"[\n                      'basicInfo.bizLicenseScope',\n                      {\n                        rules: [\n                          {\n                            required: false,\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: this.changeSelect(\n                          baseInfo.bizLicenseScopeIdList\n                        )\n                      }\n                    ]"}],staticStyle:{"min-width":"607px"},attrs:{"mode":"multiple","placeholder":"请选择"}},_vm._l((_vm.bizLicenseScope),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"食品经营许可证范围"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'basicInfo.cateringLicenseScope',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择'
                          }
                        ],
                        initialValue: this.changeSelect(
                          _vm.baseInfo.cateringLicenseScopeIdList
                        )
                      }
                    ]),expression:"[\n                      'basicInfo.cateringLicenseScope',\n                      {\n                        rules: [\n                          {\n                            required: false,\n                            message: '请选择'\n                          }\n                        ],\n                        initialValue: this.changeSelect(\n                          baseInfo.cateringLicenseScopeIdList\n                        )\n                      }\n                    ]"}],staticStyle:{"min-width":"607px"},attrs:{"mode":"multiple","placeholder":"请选择"}},_vm._l((_vm.cateringLicenseScope),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1)],1)],1)])],1),_c('div',{staticStyle:{"margin-top":"8px"}},[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"steps-action"},[_c('a-button',{on:{"click":_vm.cancelChange}},[_vm._v(" 取消 ")]),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary"},on:{"click":_vm.saveBaseInfo}},[_vm._v(" 保存 ")])],1)])],1),_c('a-divider'),_c('DescriptionList',{staticStyle:{"margin-bottom":"15px"},attrs:{"title":"建筑信息","size":"large"}},[_c('a-card',{staticStyle:{"margin-left":"-16px"},attrs:{"bordered":false}},[_c('a-form',{attrs:{"layout":"inline","form":_vm.facilityBuildingForm}},[_c('a-row',{staticStyle:{"line-height":"4"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店设计面积"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.nfa',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          required: true,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.nfa
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.nfa',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          required: true,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.nfa\n                    }\n                  ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" ㎡ ")],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"档口可出租面积"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.totalKitchenUsableArea',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          required: true,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.totalKitchenUsableArea
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.totalKitchenUsableArea',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          required: true,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.totalKitchenUsableArea\n                    }\n                  ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" ㎡ ")],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店电量扩容费"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityElectricityUpgradeCost',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue:
                        _vm.buildingInfo.facilityElectricityUpgradeCost
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityElectricityUpgradeCost',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue:\n                        buildingInfo.facilityElectricityUpgradeCost\n                    }\n                  ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" 元 ")],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店排烟扩容费"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityExhaustUpgradeCost',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.facilityExhaustUpgradeCost
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityExhaustUpgradeCost',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.facilityExhaustUpgradeCost\n                    }\n                  ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" 元 ")],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店燃气扩容费"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityGasUpgradeCost',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.facilityGasUpgradeCost
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityGasUpgradeCost',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.facilityGasUpgradeCost\n                    }\n                  ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" 元 ")],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店空调扩容费"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityAcUpgradeCost',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.facilityAcUpgradeCost
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityAcUpgradeCost',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.facilityAcUpgradeCost\n                    }\n                  ]"}],staticStyle:{"width":"240px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" 元 ")],1)],1),_c('a-col',{staticClass:"storeNewStoreLabelWidth2",attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店扩容前电费总量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityExistingElectricity',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.facilityExistingElectricity
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityExistingElectricity',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.facilityExistingElectricity\n                    }\n                  ]"}],staticStyle:{"width":"198px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" Kw ")],1)],1),_c('a-col',{staticClass:"storeNewStoreLabelWidth2",attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店扩容前排烟量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityExistingExhaust',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.facilityExistingExhaust
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityExistingExhaust',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.facilityExistingExhaust\n                    }\n                  ]"}],staticStyle:{"width":"198px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" ㎡/h ")],1)],1),_c('a-col',{staticClass:"storeNewStoreLabelWidth2",attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店扩容前燃气量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityExistingGas',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.facilityExistingGas
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityExistingGas',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.facilityExistingGas\n                    }\n                  ]"}],staticStyle:{"width":"198px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" ㎡ ")],1)],1),_c('a-col',{staticClass:"storeNewStoreLabelWidth2",attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店扩容后总电量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityPostUpgradeElectricity',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue:
                        _vm.buildingInfo.facilityPostUpgradeElectricity
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityPostUpgradeElectricity',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue:\n                        buildingInfo.facilityPostUpgradeElectricity\n                    }\n                  ]"}],staticStyle:{"width":"198px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" Kw ")],1)],1),_c('a-col',{staticClass:"storeNewStoreLabelWidth2",attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店扩容后排烟量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityPostUpgradeExhaust',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.facilityPostUpgradeExhaust
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityPostUpgradeExhaust',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.facilityPostUpgradeExhaust\n                    }\n                  ]"}],staticStyle:{"width":"198px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" ㎡/h ")],1)],1),_c('a-col',{staticClass:"storeNewStoreLabelWidth2",attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店扩容后燃气量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityPostUpgradeGas',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.facilityPostUpgradeGas
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityPostUpgradeGas',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.facilityPostUpgradeGas\n                    }\n                  ]"}],staticStyle:{"width":"198px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" ㎡ ")],1)],1),_c('a-col',{staticClass:"storeNewStoreLabelWidth2",attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店扩容前空调量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityExistingAc',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.facilityExistingAc
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityExistingAc',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.facilityExistingAc\n                    }\n                  ]"}],staticStyle:{"width":"198px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" W ")],1)],1),_c('a-col',{staticClass:"storeNewStoreLabelWidth2",attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店扩容后空调量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityBuildingDTO.facilityPostUpgradeAc',
                    {
                      rules: [
                        {
                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.buildingInfo.facilityPostUpgradeAc
                    }
                  ]),expression:"[\n                    'facilityBuildingDTO.facilityPostUpgradeAc',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: buildingInfo.facilityPostUpgradeAc\n                    }\n                  ]"}],staticStyle:{"width":"198px"},attrs:{"disabled":_vm.isREP,"placeholder":"请输入"}}),_vm._v(" W ")],1)],1)],1),_c('a-row',{staticStyle:{"line-height":"8","margin-top":"18px"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{staticClass:"fileBox",attrs:{"label":"平面规划图","labelCol":{ span: 24 }}},[_c('div',{staticClass:"imgBox"},_vm._l((_vm.floorPlanMedias.filter(
                      item => item.fType == 'img'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('img',{staticClass:"image-edit",attrs:{"slot":"cover","alt":"example","src":item.url},on:{"click":function($event){return _vm.viewImg(item.url)}},slot:"cover"}),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'floorPlanMedias')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"otherBox"},_vm._l((_vm.floorPlanMedias.filter(
                      item => item.fType == 'other'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('a',{attrs:{"target":"_blank","href":item.url,"download":item.url}},[_vm._v("[附件"+_vm._s(index + 1)+"]")]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'floorPlanMedias')}}},[_vm._v("X")])])}),0),_c('CompressUpload',{attrs:{"onFinish":_vm.onBuildingUploadFinish}})],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{staticClass:"fileBox",attrs:{"label":"门店3D渲染图","labelCol":{ span: 24 }}},[_c('div',{staticClass:"imgBox"},_vm._l((_vm.designRenderMedias.filter(
                      item => item.fType == 'img'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('img',{staticClass:"image-edit",attrs:{"slot":"cover","alt":"example","src":item.url},on:{"click":function($event){return _vm.viewImg(item.url)}},slot:"cover"}),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'designRenderMedias')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"otherBox"},_vm._l((_vm.designRenderMedias.filter(
                      item => item.fType == 'other'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('a',{attrs:{"target":"_blank","href":item.url,"download":item.url}},[_vm._v("[附件"+_vm._s(index + 1)+"]")]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'designRenderMedias')}}},[_vm._v("X")])])}),0),_c('CompressUpload',{attrs:{"onFinish":_vm.on3DUploadFinish}})],1)],1)],1)],1)],1),_c('div',{staticStyle:{"margin-top":"8px"}},[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"steps-action"},[_c('a-button',{on:{"click":_vm.cancelChange}},[_vm._v(" 取消 ")]),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary"},on:{"click":_vm.saveBuildingInfo}},[_vm._v(" 保存 ")])],1)])],1)],1)],1),(_vm.visibleImg)?_c('ImagePreviewModal',{attrs:{"imageUrls":_vm.showImgUrl,"closeAction":_vm.hideImageModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }