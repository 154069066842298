/* 
  上传组件，对于太大的图片会进行压缩
*/
<template>
  <a-spin :spinning="loading">
    <a-upload
      :showUploadList="false"
      :multiple="false"
      :accept="accept"
      withCredentials
      list-type="picture-card"
      name="upload"
      :customRequest="beforeUpload"
    >
      <div>
        <a-icon type="plus" />
        <div class="ant-upload-text">上传</div>
      </div>
    </a-upload>
  </a-spin>
</template>
<script>
import Compressor from "compressorjs";
import { modal } from "ant-design-vue";
  

export default {
  props: {
    limitSize: {
      type: Number,
      default: 10 * 1024 * 1024,
    },
    onFinish: {
      type: Function,
    },
    accept: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    beforeUpload(req) {
      let that = this;
      if (req.file.name.split(".").length < 2) {
        this.$message.error("上传失败，文件没有后缀名！");
      } else if (req.file.type.includes("image") && req.file.size >= this.limitSize) {
        modal.confirm({
          title: "由于文件太大，所选图片将会被压缩，确定要上传吗？",
          onOk() {
            that.loading = true;
            new Compressor(req.file, {
              quality: Math.min(that.limitSize / req.file.size, 0.6),
              success(result) {
                that.handleUpload(result);
              },
              error(err) {
                that.loading = false;
                that.$message.error(`压缩文件失败`);
              },
            });
          },
          onCancel() {
            // 也许需要一个cancel action
          },
        });
      } else {
        this.loading = true;
        this.handleUpload(req.file);
      }
    },
    handleUpload(file) {
      var formData = new FormData();
      formData.append("multipartFile", file, file.name);
      axios({
        url:   this.$baseUrl + "upload/ossFile",
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        this.onFinish(res);
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
</style>