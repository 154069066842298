<template>
  <div style="padding:0;">
    <a-spin :spinning="rentInfoLoading">
      <DescriptionList title="承租信息" size="large">
        <a-card :bordered="false" style="margin-left: -16px;">
          <a-form layout="inline" :form="rentInfoForm">
            <a-row :gutter="0" style="line-height: 4">
              <a-col :md="8" :sm="24">
                <a-form-item label="业主/房东主要联系人">
                  <a-input
                    style="width: 200px"
                    v-decorator="[
                      'facilityLeaseDTO.landLoad',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.landLoad
                      }
                    ]"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="业主/房东物业管理联系人">
                  <a-input
                    style="width: 200px"
                    v-decorator="[
                      'facilityLeaseDTO.propertyManager',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.propertyManager
                      }
                    ]"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="业主/房东财务联系人">
                  <a-input
                    style="width: 200px"
                    v-decorator="[
                      'facilityLeaseDTO.financeManager',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.financeManager
                      }
                    ]"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="业主/房东主要联系人电话">
                  <a-input
                    style="width: 200px"
                    v-decorator="[
                      'facilityLeaseDTO.landLoadPhone',
                      {
                        rules: [
                          {
                            pattern: /^1\d{10}$/,
                            required: false,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.landLoadPhone
                      }
                    ]"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="业主/房东物业管理联系电话">
                  <a-input
                    style="width: 200px"
                    v-decorator="[
                      'facilityLeaseDTO.pmPhone',
                      {
                        rules: [
                          {
                            pattern: /^1\d{10}$/,
                            required: false,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.pmPhone
                      }
                    ]"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="业主/房东财务电话">
                  <a-input
                    style="width: 200px"
                    v-decorator="[
                      'facilityLeaseDTO.financePhone',
                      {
                        rules: [
                          {
                            pattern: /^1\d{10}$/,
                            required: false,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.financePhone
                      }
                    ]"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </DescriptionList>
      <a-divider />
      <DescriptionList title="承租合同信息" size="large">
        <a-card :bordered="false">
          <a-form layout="inline" :form="rentInfoForm">
            <a-row :gutter="5" style="line-height: 4">
              <a-col :md="12" :sm="24">
                <a-form-item label="是否有转租合同">
                  <a-select
                    style="width: 200px"
                    disabled
                    v-decorator="[
                      'facilityLeaseDTO.subleaseAuth',
                      {
                        rules: [
                          {
                            message: '请选择'
                          }
                        ],
                        initialValue: baseInfo.subleaseAuth
                      }
                    ]"
                    placeholder="无"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="承租合同开始日期">
                  <a-date-picker
                    :disabled="isREP"
                    v-decorator="[
                      'facilityLeaseDTO.lesseeStartDate',
                      {
                        initialValue: baseInfo.lesseeStartDateLg
                          ? moment(baseInfo.lesseeStartDateLg)
                          : ''
                      }
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="承租合同结束日期">
                  <a-date-picker
                    :disabled="isREP"
                    v-decorator="[
                      'facilityLeaseDTO.lesseeEndDate',
                      {
                        initialValue: baseInfo.lesseeEndDateLg
                          ? moment(baseInfo.lesseeEndDateLg)
                          : ''
                      }
                    ]"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </DescriptionList>
      <a-divider />
      <DescriptionList title="费用信息" size="large">
        <a-card :bordered="false">
          <a-form layout="inline" :form="rentInfoForm">
            <a-row :gutter="5" style="line-height: 4">
              <a-col :md="8" :sm="24">
                <a-form-item label="承租电费">
                  <a-input
                    style="width: 240px"
                    v-decorator="[
                      'facilityLeaseDTO.lesseeElecPrice',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            required: true,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.lesseeElecPrice
                      }
                    ]"
                    placeholder="请输入"
                  />
                  元/度
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="承租水费">
                  <a-input
                    style="width: 240px"
                    v-decorator="[
                      'facilityLeaseDTO.lesseeWaterPrice',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            required: true,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.lesseeWaterPrice
                      }
                    ]"
                    placeholder="请输入"
                  />
                  元/吨
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="承租燃气">
                  <a-input
                    style="width: 240px"
                    v-decorator="[
                      'facilityLeaseDTO.lesseeGasPrice',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            required: true,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.lesseeGasPrice
                      }
                    ]"
                    placeholder="请输入"
                  />
                  元/m³
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </DescriptionList>
      <div style="margin-top: 8px">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="cancelChange">
              取消
            </a-button>
            <a-button
              type="primary"
              style="margin-left: 8px"
              @click="saveRentInfo"
            >
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import DescriptionList from "@/components/DescriptionList";
  
export default {
  name: "RentDetailEdit",
  props: {
    baseInfo: {},
    //
    initPage: {
      type: Function,
      default: null
    },
    isREP: {
      type: Boolean,
      default: true
    }
  },
  components: {
    DescriptionList
  },
  data() {
    return {
      // 更改信息
      rentInfoForm: this.$form.createForm(this),
      //
      rentInfoLoading: false,
      //是否
      yesOrNo: [
        {
          id: "Y",
          name: "是"
        },
        {
          id: "N",
          name: "否"
        }
      ]
    };
  },
  watch: {},
  created() {},
  computed: {},
  methods: {
    // 取消
    cancelChange() {
      this.$router.push({ path: "/store/storeList" });
    },
    moment,
    //修改建筑信息
    saveRentInfo() {
      // this.rentInfoLoading = true;
      const {
        rentInfoForm: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          this.submitRentInfo(values);
        } else {
          this.rentInfoLoading = false;
        }
      });
    },
    // submitRentInfo
    submitRentInfo(val) {
      axios({
        url:   this.$baseUrl + "/facility/modify/lessee",
        method: "POST",
        withCredentials: true,
        data: {
          ...val,
          id: this.baseInfo.id,
          step: 2
        }
      })
        .then(res => {
          if (res.data.returnCode == "200") {
            this.initPage();
            this.$message.success("保存成功！");
          } else {
            this.$message.error("保存失败");
          }
          this.rentInfoLoading = false;
        })
        .catch(error => {
          this.rentInfoLoading = false;
          this.$message.error(error.message);
        });
    }
  }
};
</script>
<style lang="less" scoped>
.p18 {
  padding: 18px;
}
</style>
