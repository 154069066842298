var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"0"}},[_c('a-spin',{attrs:{"spinning":_vm.dataLoading}},[_c('DescriptionList',{attrs:{"title":"证照信息","size":"large"}},[_c('a-card',{staticStyle:{"margin-left":"-16px"},attrs:{"bordered":false}},[_c('a-form',{attrs:{"layout":"inline","form":_vm.licenceDetailForm}},[_c('a-row',{staticStyle:{"line-height":"4"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"门店主体名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.facilityEntity',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.baseInfo.entity
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.facilityEntity',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: baseInfo.entity\n                    }\n                  ]"}],staticStyle:{"min-width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"营业期限"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.bizLicenseDate',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请选择'
                        }
                      ],
                      initialValue: [
                        _vm.baseInfo.bizLicenseStartDateLg
                          ? _vm.moment(_vm.baseInfo.bizLicenseStartDateLg)
                          : '',
                        _vm.baseInfo.bizLicenseEndDateLg
                          ? _vm.moment(_vm.baseInfo.bizLicenseEndDateLg)
                          : ''
                      ]
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.bizLicenseDate',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请选择'\n                        }\n                      ],\n                      initialValue: [\n                        baseInfo.bizLicenseStartDateLg\n                          ? moment(baseInfo.bizLicenseStartDateLg)\n                          : '',\n                        baseInfo.bizLicenseEndDateLg\n                          ? moment(baseInfo.bizLicenseEndDateLg)\n                          : ''\n                      ]\n                    }\n                  ]"}]})],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"统一社会信用代码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.socialCode',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.baseInfo.socialCode
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.socialCode',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: baseInfo.socialCode\n                    }\n                  ]"}],staticStyle:{"min-width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"营业执照范围"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.bizLicenseScope',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请选择!'
                        }
                      ],
                      initialValue: this.changeSelect(
                        _vm.baseInfo.bizLicenseScopeIdList
                      )
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.bizLicenseScope',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请选择!'\n                        }\n                      ],\n                      initialValue: this.changeSelect(\n                        baseInfo.bizLicenseScopeIdList\n                      )\n                    }\n                  ]"}],staticStyle:{"min-width":"400px"},attrs:{"mode":"multiple","placeholder":"请选择"}},_vm._l((_vm.bizLicenseScope),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-divider',{attrs:{"dashed":""}}),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"FDA食药监是否通过"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.fdaStatus',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请选择'
                        }
                      ],
                      initialValue: _vm.baseInfo.fdaStatus
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.fdaStatus',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请选择'\n                        }\n                      ],\n                      initialValue: baseInfo.fdaStatus\n                    }\n                  ]"}],staticStyle:{"min-width":"200px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.yesOrNo),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"食品经营许可证"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.foodCertificateDate',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请选择!'
                        }
                      ],
                      initialValue: [
                        _vm.baseInfo.foodCertificateStartDateLg
                          ? _vm.moment(_vm.baseInfo.fdaPermitStartDateLg)
                          : '',
                        _vm.baseInfo.foodCertificateEndDateLg
                          ? _vm.moment(_vm.baseInfo.fdaPermitEndDateLg)
                          : ''
                      ]
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.foodCertificateDate',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请选择!'\n                        }\n                      ],\n                      initialValue: [\n                        baseInfo.foodCertificateStartDateLg\n                          ? moment(baseInfo.fdaPermitStartDateLg)\n                          : '',\n                        baseInfo.foodCertificateEndDateLg\n                          ? moment(baseInfo.fdaPermitEndDateLg)\n                          : ''\n                      ]\n                    }\n                  ]"}]})],1)],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"食品经营许可证编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.foodCertificate',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.baseInfo.foodCertificate
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.foodCertificate',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: baseInfo.foodCertificate\n                    }\n                  ]"}],staticStyle:{"min-width":"200px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"食品经营许可证范围"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.cateringLicenseScope',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请选择!'
                        }
                      ],
                      initialValue: this.changeSelect(
                        _vm.baseInfo.cateringLicenseScopeIdList
                      )
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.cateringLicenseScope',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请选择!'\n                        }\n                      ],\n                      initialValue: this.changeSelect(\n                        baseInfo.cateringLicenseScopeIdList\n                      )\n                    }\n                  ]"}],staticStyle:{"min-width":"400px"},attrs:{"mode":"multiple","placeholder":"请选择"}},_vm._l((_vm.cateringLicenseScope),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-divider',{attrs:{"dashed":""}}),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"FSB消防验收通过"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.fsbStatus',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请选择'
                        }
                      ],
                      initialValue: _vm.baseInfo.fsbStatus
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.fsbStatus',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请选择'\n                        }\n                      ],\n                      initialValue: baseInfo.fsbStatus\n                    }\n                  ]"}],staticStyle:{"min-width":"200px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.yesOrNo),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"消防许可通过日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.fsbPassDate',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请选择日期!'
                        }
                      ],
                      initialValue: _vm.baseInfo.fsbPassDateLg
                        ? _vm.moment(_vm.baseInfo.fsbPassDateLg)
                        : ''
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.fsbPassDate',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请选择日期!'\n                        }\n                      ],\n                      initialValue: baseInfo.fsbPassDateLg\n                        ? moment(baseInfo.fsbPassDateLg)\n                        : ''\n                    }\n                  ]"}],staticStyle:{"min-width":"200px"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否有大消防证"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.fireCertificate',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请选择'
                        }
                      ],
                      initialValue: _vm.buildingInfo.fireCertificate
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.fireCertificate',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请选择'\n                        }\n                      ],\n                      initialValue: buildingInfo.fireCertificate\n                    }\n                  ]"}],staticStyle:{"min-width":"200px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.yesOrNo),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"消防许可证编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.fsbCode',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请输入'
                        }
                      ],
                      initialValue: _vm.baseInfo.fsbCode
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.fsbCode',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请输入'\n                        }\n                      ],\n                      initialValue: baseInfo.fsbCode\n                    }\n                  ]"}],staticStyle:{"min-width":"200px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否有环评备案"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.environmentAssessment',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请选择'
                        }
                      ],
                      initialValue: _vm.buildingInfo.environmentAssessment
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.environmentAssessment',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请选择'\n                        }\n                      ],\n                      initialValue: buildingInfo.environmentAssessment\n                    }\n                  ]"}],staticStyle:{"min-width":"200px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.yesOrNo),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":16,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否有红头文件"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'facilityCertificatesDTO.governmentOfficialLetter',
                    {
                      rules: [
                        {
                          required: false,
                          message: '请选择'
                        }
                      ],
                      initialValue: _vm.buildingInfo.governmentOfficialLetter
                    }
                  ]),expression:"[\n                    'facilityCertificatesDTO.governmentOfficialLetter',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '请选择'\n                        }\n                      ],\n                      initialValue: buildingInfo.governmentOfficialLetter\n                    }\n                  ]"}],staticStyle:{"min-width":"200px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.yesOrNo),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1)],1)],1)],1),_c('a-divider'),_c('DescriptionList',{attrs:{"size":"large"}},[_c('a-card',{staticStyle:{"margin-left":"-16px"},attrs:{"bordered":false}},[_c('a-form',{attrs:{"layout":"inline","form":_vm.licenceDetailForm}},[_c('a-row',{staticStyle:{"line-height":"8"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{staticClass:"fileBox",attrs:{"label":"营业执照图片","labelCol":{ span: 24 }}},[_c('div',{staticClass:"imgBox"},_vm._l((_vm.bizLicenseMedias.filter(
                      item => item.fType == 'img'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('img',{attrs:{"slot":"cover","alt":"example","src":item.url},on:{"click":function($event){return _vm.viewImg($event)}},slot:"cover"}),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'bizLicenseMedias')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"otherBox"},_vm._l((_vm.bizLicenseMedias.filter(
                      item => item.fType == 'other'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('a',{attrs:{"target":"_blank","href":item.url,"download":item.url}},[_vm._v("[附件"+_vm._s(index + 1)+"]")]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'bizLicenseMedias')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"uploadBox"},[_c('a-upload',{attrs:{"withCredentials":"","list-type":"picture-card","action":this.uploadUrl(),"name":"multipartFile"},on:{"change":_vm.uploadBizLicensePhotoFile}},[_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传")])],1)])],1)])],1),_c('a-divider',{attrs:{"dashed":""}}),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{staticClass:"fileBox",attrs:{"label":"食品经营许可证照片","labelCol":{ span: 24 }}},[_c('div',{staticClass:"imgBox"},_vm._l((_vm.foodCertificateMedias.filter(
                      item => item.fType == 'img'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('img',{attrs:{"slot":"cover","alt":"example","src":item.url},on:{"click":function($event){return _vm.viewImg($event)}},slot:"cover"}),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'foodCertificateMedias')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"otherBox"},_vm._l((_vm.foodCertificateMedias.filter(
                      item => item.fType == 'other'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('a',{attrs:{"target":"_blank","href":item.url,"download":item.url}},[_vm._v("[附件"+_vm._s(index + 1)+"]")]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'foodCertificateMedias')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"uploadBox"},[_c('a-upload',{attrs:{"withCredentials":"","list-type":"picture-card","action":this.uploadUrl(),"name":"multipartFile"},on:{"change":_vm.uploadFoodCertificateFile}},[_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传")])],1)])],1)])],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-form-item',{staticClass:"fileBox",attrs:{"label":"食药监平面图图片","labelCol":{ span: 24 }}},[_c('div',{staticClass:"imgBox"},_vm._l((_vm.fdaFloorPlanMedias.filter(
                      item => item.fType == 'img'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('img',{attrs:{"slot":"cover","alt":"example","src":item.url},on:{"click":function($event){return _vm.viewImg($event)}},slot:"cover"}),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'fdaFloorPlanMedias')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"otherBox"},_vm._l((_vm.fdaFloorPlanMedias.filter(
                      item => item.fType == 'other'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('a',{attrs:{"target":"_blank","href":item.url,"download":item.url}},[_vm._v("[附件"+_vm._s(index + 1)+"]")]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'fdaFloorPlanMedias')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"uploadBox"},[_c('a-upload',{attrs:{"withCredentials":"","list-type":"picture-card","action":this.uploadUrl(),"name":"multipartFile"},on:{"change":_vm.uploadFdaFloorPlanFile}},[_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传")])],1)])],1)])],1),_c('a-divider',{attrs:{"dashed":""}}),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{staticClass:"fileBox",attrs:{"label":"食药监平面图图片","labelCol":{ span: 24 }}},[_c('div',{staticClass:"imgBox"},_vm._l((_vm.completionMediaUrls.filter(
                      item => item.fType == 'img'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('img',{attrs:{"slot":"cover","alt":"example","src":item.url},on:{"click":function($event){return _vm.viewImg($event)}},slot:"cover"}),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'completionMediaUrls')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"otherBox"},_vm._l((_vm.completionMediaUrls.filter(
                      item => item.fType == 'other'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('a',{attrs:{"target":"_blank","href":item.url,"download":item.url}},[_vm._v("[附件"+_vm._s(index + 1)+"]")]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'completionMediaUrls')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"uploadBox"},[_c('a-upload',{attrs:{"withCredentials":"","list-type":"picture-card","action":this.uploadUrl(),"name":"multipartFile"},on:{"change":_vm.uploadCompletionFile}},[_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传")])],1)])],1)])],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{staticClass:"fileBox",attrs:{"label":"消防验收意见书(备案)","labelCol":{ span: 24 }}},[_c('div',{staticClass:"imgBox"},_vm._l((_vm.fsbMedias.filter(
                      item => item.fType == 'img'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('img',{attrs:{"slot":"cover","alt":"example","src":item.url},on:{"click":function($event){return _vm.viewImg($event)}},slot:"cover"}),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'fsbMedias')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"otherBox"},_vm._l((_vm.fsbMedias.filter(
                      item => item.fType == 'other'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('a',{attrs:{"target":"_blank","href":item.url,"download":item.url}},[_vm._v("[附件"+_vm._s(index + 1)+"]")]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'fsbMedias')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"uploadBox"},[_c('a-upload',{attrs:{"withCredentials":"","list-type":"picture-card","action":this.uploadUrl(),"name":"multipartFile"},on:{"change":_vm.uploadFsbMediasFile}},[_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传")])],1)])],1)])],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{staticClass:"fileBox",attrs:{"label":"消防安全检查合格证","labelCol":{ span: 24 }}},[_c('div',{staticClass:"imgBox"},_vm._l((_vm.fsbSecurityCheckPhoto.filter(
                      item => item.fType == 'img'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('img',{attrs:{"slot":"cover","alt":"example","src":item.url},on:{"click":function($event){return _vm.viewImg($event)}},slot:"cover"}),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'fsbSecurityCheckPhoto')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"otherBox"},_vm._l((_vm.fsbSecurityCheckPhoto.filter(
                      item => item.fType == 'other'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('a',{attrs:{"target":"_blank","href":item.url,"download":item.url}},[_vm._v("[附件"+_vm._s(index + 1)+"]")]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'fsbSecurityCheckPhoto')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"uploadBox"},[_c('a-upload',{attrs:{"withCredentials":"","list-type":"picture-card","action":this.uploadUrl(),"name":"multipartFile"},on:{"change":_vm.uploadFsbSecurityCheckFile}},[_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传")])],1)])],1)])],1),_c('a-divider',{attrs:{"dashed":""}}),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{staticClass:"fileBox",attrs:{"label":"其他资料","labelCol":{ span: 24 }}},[_c('div',{staticClass:"imgBox"},_vm._l((_vm.otherMediaUrls.filter(
                      item => item.fType == 'img'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('img',{attrs:{"slot":"cover","alt":"example","src":item.url},on:{"click":function($event){return _vm.viewImg($event)}},slot:"cover"}),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'otherMediaUrls')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"otherBox"},_vm._l((_vm.otherMediaUrls.filter(
                      item => item.fType == 'other'
                    )),function(item,index){return _c('div',{key:index,staticClass:"imgItem"},[_c('a',{attrs:{"target":"_blank","href":item.url,"download":item.url}},[_vm._v("[附件"+_vm._s(index + 1)+"]")]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeFile(item, 'otherMediaUrls')}}},[_vm._v("X")])])}),0),_c('div',{staticClass:"uploadBox"},[_c('a-upload',{attrs:{"withCredentials":"","list-type":"picture-card","action":this.uploadUrl(),"name":"multipartFile"},on:{"change":_vm.uploadOtherMediaUrlsFile}},[_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传")])],1)])],1)])],1)],1)],1)],1)],1),_c('div',{staticStyle:{"margin-top":"8px"}},[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"steps-action"},[_c('a-button',{on:{"click":_vm.cancelChange}},[_vm._v(" 取消 ")]),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary"},on:{"click":_vm.saveLicenceInfo}},[_vm._v(" 保存 ")])],1)])],1)],1),_c('a-modal',{attrs:{"width":1000,"title":"图片详情","footer":null},model:{value:(_vm.visibleImg),callback:function ($$v) {_vm.visibleImg=$$v},expression:"visibleImg"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.showImgUrl}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }