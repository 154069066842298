<template>
  <div style="padding:0;">
    <a-spin :spinning="dataLoading">
      <DescriptionList title="费用信息" size="large">
        <a-card
          :bordered="false"
          style="margin-left: -16px; margin-bottom: -24px"
        >
          <a-form layout="inline" :form="costDetailform">
            <a-row :gutter="0" style="line-height: 4">
              <a-col :md="8" :sm="24">
                <a-form-item label="出租电费">
                  <a-input
                    style="width: 160px"
                    v-decorator="[
                      'facilityCostDTO.elePrice',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            required: true,
                            message: '请输入！'
                          }
                        ],
                        initialValue: dataQueryCost.elecPrice
                      }
                    ]"
                    placeholder="请输入"
                  >
                  </a-input>
                  元
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="出租水费">
                  <a-input
                    style="width: 160px"
                    v-decorator="[
                      'facilityCostDTO.waterPrice',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            required: true,
                            message: '请输入！'
                          }
                        ],
                        initialValue: dataQueryCost.waterPrice
                      }
                    ]"
                    placeholder="请输入"
                  >
                  </a-input>
                  元
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="出租燃气费">
                  <a-input
                    style="width: 160px"
                    v-decorator="[
                      'facilityCostDTO.gasPrice',
                      {
                        rules: [
                          {
                            pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                            required: true,
                            message: '请输入！'
                          }
                        ],
                        initialValue: dataQueryCost.gasPrice
                      }
                    ]"
                    placeholder="请输入"
                  >
                  </a-input>
                  元
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
        <a-divider />
        <a-card :bordered="false" style="margin-left: -16px; margin-top: -24px">
          <a-form layout="inline" :form="costDetailform">
            <a-row :gutter="0" style="line-height: 4">
              <a-col :md="8" :sm="24">
                <a-form-item label="是否使用空调">
                  <a-select
                    style="min-width: 80px"
                    v-decorator="[
                      'facilityCostDTO.ac',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请输入'
                          }
                        ],
                        initialValue: baseInfo.ac
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否使用燃气">
                  <a-select
                    style="min-width: 80px"
                    v-decorator="[
                      'facilityCostDTO.gas',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择'
                          }
                        ],
                        initialValue: baseInfo.gas
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否使用智能电表">
                  <a-select
                    style="min-width: 80px"
                    v-decorator="[
                      'facilityCostDTO.smartMeter',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择'
                          }
                        ],
                        initialValue: buildingInfo.smartMeter
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="电是否用物业卡充值">
                  <a-select
                    style="min-width: 80px"
                    v-decorator="[
                      'facilityCostDTO.electricPrepaid',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择'
                          }
                        ],
                        initialValue: baseInfo.electricPrepaid
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="水是否用物业卡充值">
                  <a-select
                    style="min-width: 80px"
                    v-decorator="[
                      'facilityCostDTO.waterPrepaid',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择'
                          }
                        ],
                        initialValue: baseInfo.waterPrepaid
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="燃气是否用物业卡充值">
                  <a-select
                    style="min-width: 80px"
                    v-decorator="[
                      'facilityCostDTO.gasPrepaid',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择'
                          }
                        ],
                        initialValue: baseInfo.gasPrepaid
                      }
                    ]"
                    placeholder="请选择"
                  >
                    <a-select-option v-for="item in yesOrNo" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </DescriptionList>
      <div style="margin-top: 8px">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="cancelChange">
              取消
            </a-button>
            <a-button
              type="primary"
              style="margin-left: 8px"
              @click="saveCostInfo"
            >
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import DescriptionList from "@/components/DescriptionList";
  

export default {
  name: "CostDetailEdit",
  props: {
    baseInfo: {},
    dataQueryCost: {},
    buildingInfo: {},
    //
    initPage: {
      type: Function,
      default: null
    }
  },
  components: {
    DescriptionList
  },
  data() {
    return {
      costDetailform: this.$form.createForm(this),
      dataLoading: false,
      //是否
      yesOrNo: [
        {
          id: "Y",
          name: "是"
        },
        {
          id: "N",
          name: "否"
        }
      ]
    };
  },
  watch: {},
  created() {},
  computed: {},
  methods: {
    moment,
    // 取消
    cancelChange() {
      this.$router.push({ path: "/store/storeList" });
    },
    //修改建筑信息
    saveCostInfo() {
      const {
        costDetailform: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          this.submitCostInfo(values);
        } else {
          this.dataLoading = false;
        }
      });
    },
    // submitRentInfo
    submitCostInfo(val) {
      axios({
        url:   this.$baseUrl + "/facility/modify/cost",
        method: "POST",
        withCredentials: true,
        data: {
          ...val,
          id: this.baseInfo.id,
          step: 4
        }
      })
        .then(res => {
          if (res.data.returnCode == "200") {
            this.initPage();
            this.$message.success("保存成功！");
          } else {
            this.$message.error("保存失败");
          }
          this.dataLoading = false;
        })
        .catch(error => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    }
  }
};
</script>
<style lang="less" scoped>
.p18 {
  padding: 18px;
}
.mt18 {
  margin-top: 18px;
}
</style>
